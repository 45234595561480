import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ModelView } from "src/store/modelManagementSlice";
import ModelService from "src/services/modelService";
import AuditWorkflowService, {
  AuditWorkflowExtractionPayload,
} from "src/services/auditWorkflowServeice";

export enum AuditJobStatus {
  Processing = "Processing",
  Completed = "Completed",
  Error = "Error",
}

export type GetDownloadPreSignedUrlPayload = {
  jobId: string;
  s3URL: string;
  fileName: string;
};

export type AuditJob = {
  id: string;
  modelId: string;
  modelName: string;
  ownerAlias: string;
  s3OutputFilePath: string;
  inputFileName: string;
  failedIncidentsS3FilePath: string;
  status: string;
  timestamp: string;
  linesProcessed: number;
  linesFailed: number;
};

export type AuditDataUploadPageState = {
  selectedAuditModelId: string;
  auditModelViews: ModelView[];
  uploadFile: File | undefined;
  isValidate: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isError: boolean;
  auditJobs: AuditJob[];
  newCreatedAuditJobId: string;
};

const initialState: AuditDataUploadPageState = {
  selectedAuditModelId: "",
  auditModelViews: [],
  uploadFile: undefined,
  isValidate: false,
  isPending: false,
  isFulfilled: false,
  isError: false,
  auditJobs: [],
  newCreatedAuditJobId: "",
};

export const getAuditModels = createAsyncThunk("audit/getModels", async () => {
  const { data } = await ModelService.getAuditModels();
  return data;
});

export const submitAuditWorkflowExtraction = createAsyncThunk(
  "submitAuditWorkflowExtraction",
  async (payload: AuditWorkflowExtractionPayload) => {
    const { data } =
      await AuditWorkflowService.submitAuditWorkflowExtraction(payload);
    return data;
  },
);

export const getAuditJobHistory = createAsyncThunk(
  "getAuditJobHistory",
  async () => {
    const { data } = await AuditWorkflowService.getAuditWorkflowHistory();
    return data;
  },
);

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "auditDataUploadPageSlice",
  initialState,
  reducers: {
    setSelectAuditModelId: (state, { payload }) => {
      state.selectedAuditModelId = payload;
    },
    setUploadFile: (state, action) => {
      state.uploadFile = action.payload;
    },
    resetBatchExtractionApi: (state) => {
      state.isPending = false;
      state.isFulfilled = false;
      state.isError = false;
    },
    setIsValidate: (state, action) => {
      state.isValidate = action.payload;
    },
    resetNewCreatedAuditJobId: (state) => {
      state.newCreatedAuditJobId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuditModels.fulfilled, (state, { payload }) => {
      state.auditModelViews = payload;
    });
    builder.addCase(submitAuditWorkflowExtraction.pending, (state) => {
      state.isPending = true;
      state.isFulfilled = false;
      state.isError = false;
    });
    builder.addCase(
      submitAuditWorkflowExtraction.fulfilled,
      (state, { payload }) => {
        state.isPending = false;
        state.isFulfilled = true;
        state.newCreatedAuditJobId = payload.id;
      },
    );
    builder.addCase(submitAuditWorkflowExtraction.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
    builder.addCase(getAuditJobHistory.fulfilled, (state, { payload }) => {
      state.auditJobs = payload;
    });
  },
});

export const {
  setUploadFile,
  resetBatchExtractionApi,
  resetNewCreatedAuditJobId,
  setSelectAuditModelId,
} = actions;

export default reducer;
