import React from "react";
import { useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import FieldEdit from "src/components/modelManagement/fieldEdit/fieldEdit";

const FieldDetail = () => {
  const { selectedModel, selectedFieldId } = useAppSelector(
    (state) => state.modelSlice,
  );

  return (
    <Column>
      <BreadcrumbGroup>
        <Breadcrumb>{selectedModel.name}</Breadcrumb>
        <Breadcrumb>{selectedModel.fields[selectedFieldId].name}</Breadcrumb>
      </BreadcrumbGroup>
      <FieldEdit></FieldEdit>
    </Column>
  );
};

export default FieldDetail;
