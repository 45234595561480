import apiClient from "src/utils/apiClient";
import { createToast } from "src/store/toastsSlice";
import store from "src/store/store";
import { TOAST_TIMEOUT } from "src/config/Toast";

export interface ExamplePayload {
  input: string;
  output: string | string[];
}

export interface FieldPayload {
  name: string;
  description: string;
  type: string;
  options: string[];
  examples: ExamplePayload[];
}

export interface UpdateModelPayload {
  id: string | undefined;
  name: string;
  labels: FieldPayload[];
}

class ModelService {
  async getModels() {
    return await apiClient.get("/models");
  }

  async getAuditModels() {
    return await apiClient.get("/models/audit");
  }

  async getModelById(modelId: string) {
    return await apiClient.get(`/models/${modelId}`);
  }

  async upsertModel(payload: UpdateModelPayload) {
    const res = await apiClient.put(`/models`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated Model successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }
}

export default new ModelService();
