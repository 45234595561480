import apiClient from "src/utils/apiClient";
import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { AuditField } from "src/store/groundTruthSlice";

export interface UpdateGroundTruthPayload {
  model_id: string;
  sequential_id: number;
  audit_field: AuditField;
}

class GroundTruthService {
  async getGroundTruthByModelId(payload: {
    model_id: string;
    page_number: number;
    page_size: number;
  }) {
    return await apiClient.get(
      `audit/${payload.model_id}?page_number=${payload.page_number}&page_size=${payload.page_size}`,
    );
  }

  async getGroundTruthByModelIdAndSequentialId(payload: {
    model_id: string;
    sequential_id: number;
  }) {
    return await apiClient.get(
      `audit/${payload.model_id}/${payload.sequential_id}`,
    );
  }

  async getGroundTruthStatisticsByModelId(model_id: string) {
    return await apiClient.get(`audit/${model_id}/all/statistics`);
  }

  async upsertGroundTruthField(payload: UpdateGroundTruthPayload) {
    const res = await apiClient.put(
      `/audit/${payload.model_id}/${payload.sequential_id}`,
      payload.audit_field,
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated Ground Truth Field successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }
}

export default new GroundTruthService();
