import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import Divider from "@amzn/meridian/divider";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Icon from "@amzn/meridian/icon";
import chevronLeftLargeToken from "@amzn/meridian-tokens/base/icon/chevron-left-large";
import exportSmallToken from "@amzn/meridian-tokens/base/icon/export-small";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { Field, setSelectedModelId } from "src/store/modelManagementSlice";
import {
  setFieldConfirmedAnswer,
  AuditFieldType,
  updateAuditData,
  AuditField,
  getAuditDataListView,
  getAuditJobById,
  getIncidentData,
  resetAuditReviewPage,
  NO_OBJECT_INVOLVED,
} from "src/store/auditSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import { getClarification } from "src/store/dataExtractionSlice";
import "./auditReviewView.scss";
import { createSearchRegExp } from "src/helpers";
import {
  findNextUnreviewedCase,
  highlightText,
  INADEQUATE_INFORMATION,
  isMultipleAnswerEqual,
  moveToTextTop,
  removeHighlightedText,
} from "src/components/audit/helpers";
import { getAuditData } from "src/store/auditSlice";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import { AuditStatus } from "src/store/groundTruthSlice";
import Loader from "@amzn/meridian/loader";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";

const AuditReviewView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token, alias } = useAppSelector((state) => state.user);
  const {
    auditDataListView,
    selectedAuditData,
    selectedAuditJob,
    getListViewLoading,
    getAuditDataLoading,
    getAuditJobLoading,
    updateAuditDataLoading,
    incident,
    getIncidentDataLoading,
  } = useAppSelector((state) => state.auditSlice);
  const { selectedModel } = useAppSelector((state) => state.modelSlice);

  const { auditWorkflowId, incidentId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const searchRegExp = createSearchRegExp(searchQuery);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [doesClarificationExist, setDoesClarificationExist] = useState(true);
  const [auditListIndex, setAuditListIndex] = useState(0);

  useEffect(() => {
    if (token && auditWorkflowId && incidentId) {
      dispatch(
        getAuditData({
          auditWorkflowId: auditWorkflowId,
          incidentId: incidentId,
        }),
      );
    }
  }, [auditWorkflowId, incidentId, token]);

  useEffect(() => {
    if (token && auditWorkflowId && getAuditJobLoading === "idle") {
      dispatch(getAuditJobById(auditWorkflowId));
    }
  }, [auditWorkflowId, token, getAuditJobLoading]);

  useEffect(() => {
    if (token && auditWorkflowId && getListViewLoading === "idle") {
      dispatch(getAuditDataListView(auditWorkflowId));
    }
  }, [auditWorkflowId, token, getListViewLoading]);

  useEffect(() => {
    if (token && incidentId && getIncidentDataLoading === "idle") {
      dispatch(getIncidentData(incidentId));
    }
  }, [incidentId, token, getIncidentDataLoading]);

  useEffect(() => {
    if (getAuditDataLoading === "fulfilled") {
      dispatch(setSelectedModelId(selectedAuditData.modelId));
    } else if (getAuditDataLoading === "rejected") {
      navigate(`/audit/${auditWorkflowId}`);
    }
  }, [getAuditDataLoading]);

  useEffect(() => {
    if (getListViewLoading === "fulfilled" && incidentId) {
      setAuditListIndex(
        auditDataListView.findIndex(
          (auditDataListView) => auditDataListView.incidentId === incidentId,
        ),
      );
    } else if (getListViewLoading === "rejected") {
      navigate(`/audit/${auditWorkflowId}`);
    }
  }, [getListViewLoading, incidentId]);

  useEffect(() => {
    if (updateAuditDataLoading === "fulfilled" && incidentId) {
      dispatch(resetAuditReviewPage());
      const next = findNextUnreviewedCase(auditDataListView, incidentId);
      if (next) {
        navigate(`/audit/${auditWorkflowId}/${next.incidentId}`);
      } else {
        navigate(`/audit/${auditWorkflowId}`);
        dispatch(
          createToast({
            type: "success",
            message:
              "All incidents have finished reviewed, redirect to list view page...",
            timeout: TOAST_TIMEOUT,
          }),
        );
      }
    }
  }, [updateAuditDataLoading, incidentId]);

  const isAustinAnswerNoObjectInvolved = (field: AuditField) => {
    return (
      field.austinAnswer === "" &&
      field.fieldName.toLowerCase().includes("object")
    );
  };

  const isAnswerMatch = (field: AuditField, many: boolean) => {
    // # hard code should be remove after refactor backend matching rule
    if (
      field.austinAnswer === "" &&
      field.llmAnswer.toLowerCase() === NO_OBJECT_INVOLVED.toLowerCase()
    ) {
      return true;
    }
    if (
      isAnswerInadequate(field.austinAnswer) ||
      isAnswerInadequate(field.llmAnswer)
    ) {
      return false;
    }
    return (
      field.isHierarchicalMatched ||
      compareStringsIgnoreCase(field.austinAnswer, field.llmAnswer) ||
      (many && isMultipleAnswerEqual(field.austinAnswer, field.llmAnswer))
    );
  };

  const isAnswerInadequate = (answer: string) => {
    return (
      !answer ||
      compareStringsIgnoreCase(answer, "none") ||
      compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION)
    );
  };

  const isConfirmedAnswerValid = (field: AuditField) => {
    if (typeof field.confirmedAnswer === "string") {
      return (
        isAustinAnswerNoObjectInvolved(field) ||
        (field.confirmedAnswer &&
          !compareStringsIgnoreCase(
            field.confirmedAnswer,
            AuditFieldType.NEITHER,
          ))
      );
    } else {
      return field.confirmedAnswer.length > 0;
    }
  };

  const getFinalAnswer = (fieldIndex: number) => {
    const auditField = selectedAuditData.auditFields[fieldIndex];

    const many = Object.values(selectedModel.fields).find(
      (label: Field) => label.name === auditField.fieldName,
    )!.many;

    let value = auditField.confirmedAnswer;

    if (many) {
      if (typeof auditField.confirmedAnswer === "string") {
        if (auditField.confirmedAnswer.includes("|")) {
          value = auditField.confirmedAnswer.split("|");
        } else {
          value = [auditField.confirmedAnswer as string];
        }
      }
    } else {
      value = auditField.confirmedAnswer as string;
    }

    return (
      <Select
        value={value}
        onChange={(answer) => {
          dispatch(
            setFieldConfirmedAnswer({
              fieldIndex: fieldIndex,
              confirmedAnswer: answer,
            }),
          );
        }}
        placeholder={"Choose"}
        size="medium"
        width={"200px"}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        error={shouldShowError && !isConfirmedAnswerValid(auditField)}
        errorMessage={
          shouldShowError &&
          !auditField.confirmedAnswer &&
          "please select an answer"
        }
      >
        {Object.values(selectedModel.fields)
          .find((label: Field) => label.name === auditField.fieldName)
          ?.options.filter((option) => searchRegExp.test(option))
          .map((option) => <SelectOption value={option} label={option} />)}
      </Select>
    );
  };

  const getLabel = (
    auditField: AuditField,
    type: AuditFieldType,
    matching: boolean,
  ): string => {
    switch (type) {
      case AuditFieldType.LLM_CORRECT:
        return matching
          ? `${getAnswerLowerCaseAndSeparatebyComma(auditField.llmAnswer)} -- Austin/LLM`
          : `${getAnswerLowerCaseAndSeparatebyComma(auditField.llmAnswer)} -- LLM`;
      case AuditFieldType.INADEQUATE:
        return INADEQUATE_INFORMATION;
      case AuditFieldType.HUMAN_CORRECT:
        return isAustinAnswerNoObjectInvolved(auditField)
          ? `${NO_OBJECT_INVOLVED} -- Austin`
          : `${getAnswerLowerCaseAndSeparatebyComma(auditField.austinAnswer)} -- Austin`;
      default:
        return "Others";
    }
  };

  const getConfirmedAnswer = (
    auditField: AuditField,
    type: AuditFieldType,
    many: boolean,
  ) => {
    if (type === AuditFieldType.HUMAN_CORRECT) {
      return auditField.austinAnswer;
    } else if (type === AuditFieldType.LLM_CORRECT) {
      return auditField.llmAnswer;
    } else if (type === AuditFieldType.INADEQUATE) {
      return INADEQUATE_INFORMATION;
    } else if (type === AuditFieldType.NEITHER) {
      return many ? [] : AuditFieldType.NEITHER;
    }
  };

  const getConfirmedAnswerOption = (auditField: AuditField) => {
    if (
      !isAustinAnswerNoObjectInvolved(auditField) &&
      !auditField.confirmedAnswer
    ) {
      return AuditFieldType.LLM_CORRECT;
    }
    if (typeof auditField.confirmedAnswer === "string") {
      if (
        compareStringsIgnoreCase(
          auditField.confirmedAnswer,
          INADEQUATE_INFORMATION,
        )
      ) {
        return AuditFieldType.INADEQUATE;
      } else if (
        compareStringsIgnoreCase(
          auditField.confirmedAnswer,
          auditField.llmAnswer,
        )
      ) {
        return AuditFieldType.LLM_CORRECT;
      } else if (
        compareStringsIgnoreCase(
          auditField.confirmedAnswer,
          auditField.austinAnswer,
        )
      ) {
        return AuditFieldType.HUMAN_CORRECT;
      } else if (
        compareStringsIgnoreCase(
          auditField.confirmedAnswer,
          AuditFieldType.NEITHER,
        )
      ) {
        return AuditFieldType.NEITHER;
      }
    }
    return AuditFieldType.NEITHER;
  };

  const showExtraOptions = (auditField: AuditField) => {
    return (
      auditField.confirmedAnswer &&
      (auditField.confirmedAnswer === AuditFieldType.NEITHER ||
        (auditField.confirmedAnswer !== auditField.austinAnswer &&
          auditField.confirmedAnswer !== auditField.llmAnswer &&
          auditField.confirmedAnswer !== INADEQUATE_INFORMATION))
    );
  };

  const getAnswerLowerCaseAndSeparatebyComma = (answer: string) => {
    if (answer.includes("|")) {
      return answer.split("|").join(", ");
    }
    return answer.toLocaleLowerCase();
  };

  const isFieldMultiSelected = (auditField: AuditField) => {
    return (
      Object.values(selectedModel.fields).find(
        (field) => field.name === auditField.fieldName,
      )?.many || false
    );
  };

  const saveAuditDataButton = () => {
    {
      return updateAuditDataLoading === "pending" ? (
        <Button size="large">
          <Loader size={"small"} />
        </Button>
      ) : (
        <Button
          size="large"
          onClick={() => {
            if (
              !selectedAuditData.auditFields.some(
                (auditField) => !isConfirmedAnswerValid(auditField),
              )
            ) {
              dispatch(
                updateAuditData({
                  auditData: selectedAuditData,
                  reviewer: alias,
                }),
              );
              setShouldShowError(false);
            } else {
              setShouldShowError(true);
            }
          }}
        >
          Save & Next
        </Button>
      );
    }
  };

  return (
    <Column spacingInset={"400"} spacing={"none"}>
      <BreadcrumbGroup>
        <Breadcrumb onClick={() => navigate(`/audit`)}>
          Audit workflow
        </Breadcrumb>
        <Breadcrumb onClick={() => navigate(`/audit/${auditWorkflowId}`)}>
          {selectedAuditJob.modelName} - Processed
        </Breadcrumb>
        <Breadcrumb>{selectedAuditData.caseId}</Breadcrumb>
      </BreadcrumbGroup>
      <Row widths={["80%", "20%"]}>
        <Row spacing={"400"}>
          <div
            onClick={() => {
              if (auditDataListView[auditListIndex - 1]) {
                navigate(
                  `/audit/${auditWorkflowId}/${auditDataListView[auditListIndex - 1].incidentId}`,
                );
              } else {
                navigate(`/audit/${auditWorkflowId}`);
              }
            }}
          >
            <Column spacingInset={"300"} className="icon">
              <Icon tokens={chevronLeftLargeToken} />
            </Column>
          </div>
          <Text type="h500">
            <p className="weight">Case ID: {selectedAuditData.caseId}</p>
          </Text>
          <Link
            href={
              incident.url ||
              `https://na.ehs-amazon.com/incidents/incident/${selectedAuditData.incidentId}/summary`
            }
          >
            Link to Austin Case
          </Link>
          <Icon tokens={exportSmallToken} />
          <Text>
            <p className="weight">Status: {selectedAuditData.status}</p>
          </Text>
          {selectedAuditData.status === AuditStatus.REVIEWED && (
            <Text>
              <p className="weight">
                Reviewed by: {selectedAuditData.reviewer}
              </p>
            </Text>
          )}
        </Row>
        <Row alignmentHorizontal="end" widths={["60%", "40%"]}>
          <Text type="h500">
            {auditDataListView.length > 0 && (
              <p className="weight">{`${auditListIndex + 1} / ${auditDataListView.length}`}</p>
            )}
          </Text>
          <Row widths={"fill"} alignmentHorizontal="center">
            {saveAuditDataButton()}
          </Row>
        </Row>
      </Row>
      <Divider></Divider>
      <Row
        widths={["35%", "fit", "65%"]}
        alignmentVertical="top"
        spacing={"none"}
      >
        <Column height="76vh" overflowY="auto" spacingInset={"0 400 0 400"}>
          <Text
            id="incident-description-container"
            fontFamily="amazonEmber"
            type="b500"
            color="primary"
          >
            {!doesClarificationExist && (
              <Column spacingInset={"500 0 0 0"}>
                <Alert type="warning">No phrase identified</Alert>
              </Column>
            )}
            <p id="incident-description" className="paragraph-style">
              {selectedAuditData.incidentDescription}
            </p>
          </Text>
        </Column>
        <Column className="audit-review-split-line"></Column>
        <Column height="76vh" overflowY="auto">
          <Table showDividers={true} fixHeaderRows={true} headerRows={1}>
            <TableRow backgroundColor={meridianColors.colorGray50}>
              <TableCell width={"1%"}></TableCell>
              <TableCell width={"20%"}>
                <Text type="h200">Fields</Text>
              </TableCell>
              <TableCell width={"20%"}>
                <Text type="h200">Austin Output</Text>
              </TableCell>
              <TableCell width={"20%"}>
                <Text type="h200">LLM Output</Text>
              </TableCell>
              <TableCell width={"20%"} alignmentHorizontal={"center"}>
                <Text type="h200">Review Decision</Text>
              </TableCell>
              <TableCell width={"20%"}></TableCell>
            </TableRow>
            {selectedAuditData.auditFields.map((auditField, index) => {
              return (
                <TableRow
                  highlightOnHover
                  onMouseEnter={() => {
                    // set timeout to solve the UI mismatch caused by
                    // clarificationExist variable compete when hover mouse enter and leave
                    setTimeout(() => {
                      if (auditField.llmClarification.length > 0) {
                        highlightText(
                          getClarification(auditField.llmClarification),
                        );
                      } else {
                        moveToTextTop();
                        setDoesClarificationExist(false);
                      }
                    }, 100);
                  }}
                  onMouseLeave={() => {
                    setDoesClarificationExist(true);
                    removeHighlightedText();
                  }}
                >
                  <TableCell width={"1%"}></TableCell>
                  <TableCell width={"20%"}>{auditField.fieldName}</TableCell>
                  <TableCell width={"20%"}>
                    <Text
                      color={
                        isAnswerMatch(
                          auditField,
                          isFieldMultiSelected(auditField),
                        )
                          ? "primary"
                          : "error"
                      }
                    >
                      {getAnswerLowerCaseAndSeparatebyComma(
                        auditField.austinAnswer,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell width={"20%"}>
                    <Text
                      color={
                        isAnswerMatch(
                          auditField,
                          isFieldMultiSelected(auditField),
                        )
                          ? "primary"
                          : "error"
                      }
                    >
                      {getAnswerLowerCaseAndSeparatebyComma(
                        auditField.llmAnswer,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell width={"20%"} alignmentHorizontal={"center"}>
                    <Row alignmentHorizontal={"center"}>
                      <Select
                        width={"200px"}
                        value={getConfirmedAnswerOption(auditField)}
                        onChange={(value) => {
                          dispatch(
                            setFieldConfirmedAnswer({
                              fieldIndex: index,
                              confirmedAnswer: getConfirmedAnswer(
                                auditField,
                                value,
                                isFieldMultiSelected(auditField),
                              ),
                            }),
                          );
                        }}
                        placeholder={"Choose output..."}
                        size="medium"
                        error={
                          shouldShowError && !isConfirmedAnswerValid(auditField)
                        }
                        errorMessage={
                          shouldShowError &&
                          !isConfirmedAnswerValid(auditField) &&
                          "please select a answer"
                        }
                      >
                        {!isAnswerInadequate(auditField.llmAnswer) && (
                          <SelectOption
                            key={AuditFieldType.LLM_CORRECT}
                            value={AuditFieldType.LLM_CORRECT}
                            label={getLabel(
                              auditField,
                              AuditFieldType.LLM_CORRECT,
                              isAnswerMatch(
                                auditField,
                                isFieldMultiSelected(auditField),
                              ),
                            )}
                          />
                        )}
                        {!isAnswerMatch(
                          auditField,
                          isFieldMultiSelected(auditField),
                        ) &&
                          (!isAnswerInadequate(auditField.austinAnswer) ||
                            isAustinAnswerNoObjectInvolved(auditField)) && (
                            <SelectOption
                              key={AuditFieldType.HUMAN_CORRECT}
                              value={AuditFieldType.HUMAN_CORRECT}
                              label={getLabel(
                                auditField,
                                AuditFieldType.HUMAN_CORRECT,
                                false,
                              )}
                            />
                          )}
                        <SelectOption
                          key={AuditFieldType.INADEQUATE}
                          value={AuditFieldType.INADEQUATE}
                          label={getLabel(
                            auditField,
                            AuditFieldType.INADEQUATE,
                            isAnswerInadequate(auditField.llmAnswer),
                          )}
                        />
                        <SelectOption
                          key={AuditFieldType.NEITHER}
                          value={AuditFieldType.NEITHER}
                          label={getLabel(
                            auditField,
                            AuditFieldType.NEITHER,
                            false,
                          )}
                        />
                      </Select>
                    </Row>
                  </TableCell>
                  <TableCell width={"20%"}>
                    <Row alignmentHorizontal={"center"}>
                      {selectedModel.id !== "" &&
                        showExtraOptions(auditField) &&
                        getFinalAnswer(index)}
                    </Row>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Column>
      </Row>
    </Column>
  );
};

export default AuditReviewView;
