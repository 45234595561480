import React from "react";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import Theme from "@amzn/meridian/theme";
import { useAppSelector } from "src/store/store";
import { onCloseToast } from "src/store/toastsSlice";
import { useDispatch } from "react-redux";

const SiteToaster = () => {
  const dispatch = useDispatch();
  const { toasts } = useAppSelector((state) => state.toasts);

  return (
    <Toaster
      toasts={toasts}
      onCloseToast={(toastId) => dispatch(onCloseToast(toastId))}
    >
      {(toast) => (
        <Theme>
          <Alert
            data-cy="toast-container"
            toast={true}
            type={toast.type}
            onClose={toast.onClose}
          >
            {toast.message}
          </Alert>
        </Theme>
      )}
    </Toaster>
  );
};

export default SiteToaster;
