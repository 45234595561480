import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import {
  batchExtraction,
  setUploadFile,
  setGenerateLLMClarification,
} from "src/store/batchExtractionSlice";
import Button from "@amzn/meridian/button";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { FileDetailsFile } from "@amzn/meridian/file-input/file-details";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import Column from "@amzn/meridian/column";
import Toggle from "@amzn/meridian/toggle";
import React from "react";
import SampleCSVTemplateLink from "src/components/shared/csvTemplate/sampleCSVTemplateLink";
import { INCIDENT_DESCRIPTION_COLUMN_NAME } from "src/constants";
import ModelSelect from "src/components/shared/modelSelect/modelSelect";

const UploadSection = () => {
  const dispatch = useAppDispatch();
  const { uploadFile, isPending, generateLLMClarification } = useAppSelector(
    (state) => state.batchDataExtractionPageSlice,
  );
  const { selectedModelId } = useAppSelector((state) => state.modelSlice);

  const getErrorMessage = (
    uploadFile: File | undefined,
    selectedModelId: string,
  ) => {
    if (!uploadFile && selectedModelId === "") {
      return "Please upload csv document and select extraction model";
    } else if (!uploadFile) {
      return "Please upload csv document";
    } else if (selectedModelId === "") {
      return "Please select extraction model";
    }
  };

  const handleSubmitBatchProcessJob = () => {
    if (uploadFile && selectedModelId !== "") {
      dispatch(
        batchExtraction({
          file: uploadFile,
          modelId: selectedModelId,
          generateLLMClarification: generateLLMClarification,
        }),
      );
    } else {
      dispatch(
        createToast({
          type: "error",
          message: getErrorMessage(uploadFile, selectedModelId),
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
  };

  const fileInfo: FileDetailsFile = uploadFile
    ? {
        name: uploadFile.name,
        size: uploadFile.size,
      }
    : {
        name: "",
        size: 0,
      };
  return (
    <>
      <Row alignmentHorizontal={"start"}>
        <Text fontFamily="bookerly" type={"d50"}>
          Upload file to extract
        </Text>
      </Row>
      <Row alignmentHorizontal={"justify"} widths={"100%"}>
        <FileInput
          type="single"
          onFileAttached={(acceptedFiles) =>
            dispatch(setUploadFile(acceptedFiles[0]))
          }
          uploadButtonType={"secondary"}
          accept=".csv"
          showDropZone={true}
          dropZoneDirection={"row"}
        />
      </Row>
      <Row widths="100%">
        {uploadFile && (
          <FileDetails
            file={fileInfo}
            onClickRemoveFile={() => dispatch(setUploadFile(undefined))}
            cancelUploadButtonLabel={`Cancel, ${uploadFile.name}`}
            removeFileButtonLabel={`Delete, ${uploadFile.name}`}
            uploadComplete={uploadFile !== undefined}
          />
        )}
      </Row>
      <Row alignmentHorizontal={"start"}>
        <ModelSelect></ModelSelect>
      </Row>
      {selectedModelId !== "" && (
        <SampleCSVTemplateLink
          columnNames={[INCIDENT_DESCRIPTION_COLUMN_NAME]}
        ></SampleCSVTemplateLink>
      )}
      <Row alignmentHorizontal={"start"} widths={"fill"}>
        <Column>
          <Text>Generate answer explanation</Text>
          <Row>
            <Toggle
              checked={generateLLMClarification}
              onChange={() => {
                dispatch(
                  setGenerateLLMClarification(!generateLLMClarification),
                );
              }}
            ></Toggle>
          </Row>
        </Column>
      </Row>
      <Row alignmentHorizontal={"start"}>
        <Button
          onClick={() => dispatch(handleSubmitBatchProcessJob)}
          disabled={isPending}
        >
          Submit batch extraction job
        </Button>
      </Row>
    </>
  );
};

export default UploadSection;
