import {
  createSlice,
  createAsyncThunk,
  miniSerializeError,
  SerializedError,
} from "@reduxjs/toolkit";
import { LOADING_STATE } from "../types/constants";
import authService from "src/services/authService";

export type UserState = {
  token: string;
  alias: string;
  lastName: string;
  firstName: string;
  email: string;
  ldap: string[];
  userLoading: LOADING_STATE;
  authError: SerializedError | null;
  hasPermission: boolean;
  pageLastLoadTimestamp: number;
  isLoading: boolean;
};

const initialState: UserState = {
  token: "",
  alias: "",
  lastName: "",
  firstName: "",
  email: "",
  ldap: [],
  userLoading: "pending",
  authError: null,
  hasPermission: true,
  pageLastLoadTimestamp: 0,
  isLoading: false,
};

/**
 * get the authenticated user
 * @returns {Object}
 */
export const getUser = createAsyncThunk("user/getUser", async () => {
  return authService.login();
});

/** User Slice */
const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserFailure: (state) => {
      state.userLoading = "rejected";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.userLoading = "pending";
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const { alias, token, email, firstName, lastName, ldap } = payload;
      state.token = token;
      state.alias = alias;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.ldap = ldap;
      state.userLoading = "fulfilled";
      state.pageLastLoadTimestamp = Date.now();
      if (state.ldap.length === 0) {
        state.hasPermission = false;
      }
      // TODO: add JWT token to api header
    });
    builder.addCase(getUser.rejected, (state, { error }) => {
      state.authError = miniSerializeError(error);
      state.userLoading = "rejected";
      state.hasPermission = false;
    });
  },
});

export default reducer;
