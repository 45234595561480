import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExtractionService, {
  SingleExtractionPayload,
} from "src/services/extractionService";

export type ExtractionOutput = {
  field: string;
  value: string;
  quotes: string[];
};

export type DataExtractionPageState = {
  textDescription: string;
  extractionOutput: ExtractionOutput[];
  generateLLMClarification: boolean;
  isExtractionValid: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isError: boolean;
};

const initialState: DataExtractionPageState = {
  textDescription: "",
  generateLLMClarification: true,
  extractionOutput: [],
  isExtractionValid: true,
  isPending: false,
  isFulfilled: false,
  isError: false,
};

export const getClarification = (clarification: string) => {
  return clarification
    ? clarification.split("|").filter((quote) => quote !== "")
    : [];
};

// TODO: currently if set generate LLM clarification as true, LLM will output
// TODO: fieldName_clarification with [quote]reason as the answer explanation, this could get reengineer
// TODO: with cleaner format
const toExtractionOutput = (response: Record<string, string>) => {
  return Object.keys(response)
    .filter((key) => !key.endsWith("_clarification"))
    .map((key) => {
      return {
        field: key,
        value: response[key],
        quotes: getClarification(response[key + "_clarification"]),
      };
    });
};

export const singleExtraction = createAsyncThunk(
  "model/singleExtraction",
  async (payload: SingleExtractionPayload) => {
    const { data } = await ExtractionService.singleExtraction(payload);
    return data;
  },
);

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "dataExtractionPageSlice",
  initialState,
  reducers: {
    setTextDescription: (state, action) => {
      state.textDescription = action.payload;
    },
    setExtractionOutput: (state, action) => {
      state.extractionOutput = action.payload;
    },
    clearExtractionOutput: (state) => {
      state.extractionOutput = [];
    },
    setIsExtractionValid: (state, action) => {
      state.isExtractionValid = action.payload;
    },
    setGenerateLLMClarification: (state, action) => {
      state.generateLLMClarification = action.payload;
    },
    resetExtractionApi: (state) => {
      state.isPending = false;
      state.isFulfilled = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(singleExtraction.pending, (state) => {
      state.isPending = true;
      state.isFulfilled = false;
      state.isError = false;
    });
    builder.addCase(singleExtraction.fulfilled, (state, { payload }) => {
      state.extractionOutput = toExtractionOutput(payload);
      state.isPending = false;
      state.isFulfilled = true;
    });
    builder.addCase(singleExtraction.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
  },
});

export const {
  setTextDescription,
  setExtractionOutput,
  clearExtractionOutput,
  setIsExtractionValid,
  resetExtractionApi,
  setGenerateLLMClarification,
} = actions;

export default reducer;
