import { useAppDispatch, useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {
  addNewField,
  removeField,
  addNewModel,
  getModels,
  setModelName,
  validateAndUpsertModel,
  ModelStatus,
} from "src/store/modelManagementSlice";
import Input from "@amzn/meridian/input";
import React from "react";
import { DUMMY_MODEL_ID } from "src/constants";
import { isFulfilled } from "@reduxjs/toolkit";
import ScrollableSection from "src/components/shared/components/scrollableSection";
import TitleAndButton from "src/components/shared/components/titleAndButton";
import ModelRecords from "src/components/modelManagement/modelRecods";
import FieldRecords from "src/components/modelManagement/fieldRecords";
import FieldDetail from "src/components/modelManagement/fieldDetail";
import Text from "@amzn/meridian/text";

const ModelManagement = () => {
  const dispatch = useAppDispatch();
  const {
    selectedModel,
    selectedModelId,
    selectedFieldId,
    isCreateModel,
    isCreatingField,
  } = useAppSelector((state) => state.modelSlice);

  return (
    <Row
      spacingInset={"500"}
      alignmentVertical="top"
      widths={["grid-3", "fit", "grid-3", "fit", "grid-6"]}
    >
      <ScrollableSection>
        <TitleAndButton
          title="Model"
          disabled={isCreateModel}
          onClick={() => {
            dispatch(addNewModel());
          }}
        ></TitleAndButton>
        {!isCreateModel && <ModelRecords></ModelRecords>}
        {isCreateModel && selectedModelId === DUMMY_MODEL_ID && (
          <Row widths={"fill"} alignmentHorizontal={"start"}>
            <Input
              value={selectedModel.name}
              onChange={(name) => dispatch(setModelName(name))}
              type="text"
              placeholder="Enter Model Name..."
              label="Model Name"
            />
          </Row>
        )}
      </ScrollableSection>
      <div className="split-line"></div>
      <ScrollableSection>
        <TitleAndButton
          title="Field"
          disabled={
            selectedModelId == "" ||
            isCreatingField ||
            selectedModel.status === ModelStatus.inactive
          }
          onClick={() => {
            dispatch(addNewField());
          }}
        ></TitleAndButton>
        {<FieldRecords></FieldRecords>}
      </ScrollableSection>
      <div className="split-line"></div>
      <ScrollableSection>
        <Row widths={["fill", "grid-2", "grid-1"]}>
          <Column></Column>
          <Button
            size="small"
            type="tertiary"
            disabled={
              selectedFieldId === "" ||
              selectedModel.status === ModelStatus.inactive
            }
            onClick={() => dispatch(removeField())}
          >
            Delete
          </Button>
          <Column></Column>
        </Row>
        {selectedFieldId && <FieldDetail></FieldDetail>}
        {selectedFieldId === "" ? (
          <Column
            height={"60%"}
            alignmentVertical={"center"}
            alignmentHorizontal={"center"}
          >
            <Text>Please select your field</Text>
          </Column>
        ) : (
          <Row
            spacingInset={"none none none 400"}
            alignmentHorizontal={"start"}
          >
            <Button
              onClick={async () => {
                const upsertAction = await dispatch(
                  validateAndUpsertModel(selectedModel),
                );
                if (isFulfilled(upsertAction)) {
                  dispatch(getModels());
                }
              }}
            >
              Save
            </Button>
          </Row>
        )}
      </ScrollableSection>
    </Row>
  );
};

export default ModelManagement;
