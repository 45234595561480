import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { useAppSelector } from "src/store/store";
import { createToast } from "src/store/toastsSlice";

interface errorBoundaryProps {
  children: JSX.Element;
}
const errorBoundary = ({ children }: errorBoundaryProps) => {
  const dispatch = useDispatch();
  const { errors } = useAppSelector((state) => state.errorBoundary);
  useEffect(() => {
    if (errors.length > 0) {
      dispatch(
        createToast({
          message: errors[errors.length - 1],
          type: "error",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
  }, [errors]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default errorBoundary;
