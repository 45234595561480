import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import { setUploadFile } from "src/store/auditDataUploadSlice";
import Button from "@amzn/meridian/button";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { FileDetailsFile } from "@amzn/meridian/file-input/file-details";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import React from "react";
import SampleCSVTemplateLink from "src/components/shared/csvTemplate/sampleCSVTemplateLink";
import { INCIDENT_ID_COLUMN_NAME } from "src/constants";
import { submitAuditWorkflowExtraction } from "src/store/auditDataUploadSlice";

const AuditInputUploadSection = () => {
  const dispatch = useAppDispatch();
  const { uploadFile, isPending, auditModelViews } = useAppSelector(
    (state) => state.auditDataUploadSlice,
  );

  const baseModelId = auditModelViews.filter(
    (model) => model.name === "Base Incident extraction model",
  )[0]?.id;

  const getErrorMessage = (uploadFile: File | undefined) => {
    if (!uploadFile) {
      return "Please upload csv document";
    }
  };

  const handleSubmitAuditJob = () => {
    if (uploadFile && baseModelId) {
      dispatch(
        submitAuditWorkflowExtraction({
          file: uploadFile,
          modelId: baseModelId,
        }),
      );
    } else {
      dispatch(
        createToast({
          type: "error",
          message: getErrorMessage(uploadFile),
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
  };

  const fileInfo: FileDetailsFile = uploadFile
    ? {
        name: uploadFile.name,
        size: uploadFile.size,
      }
    : {
        name: "",
        size: 0,
      };

  return (
    <Column>
      <Row>
        <Text type={"h400"}>Upload batch file</Text>
      </Row>
      <Row>
        <FileInput
          type="single"
          onFileAttached={(acceptedFiles) =>
            dispatch(setUploadFile(acceptedFiles[0]))
          }
          uploadButtonType={"secondary"}
          accept=".csv"
          showDropZone={true}
          dropZoneDirection={"row"}
        />
      </Row>
      <Row>
        {uploadFile && (
          <FileDetails
            file={fileInfo}
            onClickRemoveFile={() => dispatch(setUploadFile(undefined))}
            cancelUploadButtonLabel={`Cancel, ${uploadFile.name}`}
            removeFileButtonLabel={`Delete, ${uploadFile.name}`}
            uploadComplete={uploadFile !== undefined}
          />
        )}
      </Row>
      <SampleCSVTemplateLink
        columnNames={[INCIDENT_ID_COLUMN_NAME]}
      ></SampleCSVTemplateLink>
      <Row>
        <Button
          onClick={() => dispatch(handleSubmitAuditJob)}
          disabled={isPending}
        >
          Extract
        </Button>
      </Row>
    </Column>
  );
};

export default AuditInputUploadSection;
