import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import _ from "lodash";
import {
  getGroundTruthByModelId,
  GroundTruthAuditData,
  AuditField,
  AuditStatus,
  setCurrentPage,
  setItemPerPage,
  getGroundTruthStatisticsByModelId,
} from "src/store/groundTruthSlice";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Input from "@amzn/meridian/input";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useNavigate, useParams } from "react-router-dom";

const GroundTruthListView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { modelId } = useParams();

  const getGTStatus = (gt: GroundTruthAuditData) => {
    if (getNumOfUnreviewedField(gt) > 0) {
      return "UNREVIEWED";
    } else {
      return "REVIEWED";
    }
  };

  const {
    groundTruthList,
    currentPage,
    itemsPerPage,
    groundTruthTotalCount,
    groundTruthReviewedCount,
  } = useAppSelector((state) => state.groundTruthSlice);

  const { token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (modelId && token) {
      dispatch(getGroundTruthStatisticsByModelId(modelId));
      dispatch(
        getGroundTruthByModelId({
          model_id: modelId,
          page_number: currentPage,
          page_size: itemsPerPage,
        }),
      );
    }
  }, [token]);

  useEffect(() => {
    if (modelId && token) {
      dispatch(
        getGroundTruthByModelId({
          model_id: modelId,
          page_number: currentPage,
          page_size: itemsPerPage,
        }),
      );
    }
  }, [currentPage, itemsPerPage]);

  const numberOfPages = Math.ceil(groundTruthTotalCount / itemsPerPage);

  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;

  const getNumOfUnreviewedField = (groundTruth: GroundTruthAuditData) => {
    return groundTruth.auditFields.filter(
      (auditField: AuditField) =>
        auditField.auditStatus === AuditStatus.UNREVIEWED,
    ).length;
  };

  return (
    <>
      <Column
        width="100%"
        alignmentVertical={"center"}
        alignmentHorizontal="center"
        spacingInset={"400"}
      >
        <Row
          width="70%"
          widths={["20%", "20%", "20%", "30%"]}
          spacingInset={"400"}
          alignmentVertical={"baseline"}
        >
          <Column>
            <Text>Total ground Truth:</Text>
            <Text>{groundTruthTotalCount}</Text>
          </Column>
          <Column>
            <Text>Review ground Truth:</Text>
            <Text>{groundTruthReviewedCount}</Text>
          </Column>
          <Select
            value={itemsPerPage}
            onChange={(pageNum) => {
              dispatch(setItemPerPage(pageNum));
            }}
            label={"page size: "}
            placeholder={"please select page size..."}
            size="medium"
            width={"100%"}
          >
            <SelectOption key={20} value={20} label={20} />
            <SelectOption key={50} value={50} label={50} />
            <SelectOption key={100} value={100} label={100} />
          </Select>
          <Input
            id="my-input"
            value={currentPage}
            label={`Enter page number to navigate (1 ~ ${numberOfPages})`}
            onChange={(newPage) => dispatch(setCurrentPage(newPage))}
            type="number"
            placeholder="Enter value..."
          />
        </Row>
      </Column>
      <Row
        width="100%"
        alignmentVertical={"center"}
        alignmentHorizontal="center"
        spacingInset={"400"}
      >
        <Column
          width="80%"
          alignmentVertical={"center"}
          alignmentHorizontal="center"
        >
          <Table headerRows={1} showStripes={true}>
            <TableRow>
              <TableCell>Row number</TableCell>
              <TableCell>Incident id</TableCell>
              <TableCell>Unreviewed field</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
            {_.range(firstVisibleIndex, lastVisibleIndex + 1).map(
              (index) =>
                groundTruthList[index] && (
                  <TableRow
                    key={groundTruthList[index].incidentId}
                    onClick={() => {
                      navigate(
                        `/groundTruth/${modelId}/${groundTruthList[index].sequentialId}`,
                      );
                    }}
                  >
                    <TableCell>{groundTruthList[index].sequentialId}</TableCell>
                    <TableCell>{groundTruthList[index].incidentId}</TableCell>
                    <TableCell>
                      {getNumOfUnreviewedField(groundTruthList[index])}
                    </TableCell>
                    <TableCell>{getGTStatus(groundTruthList[index])}</TableCell>
                  </TableRow>
                ),
            )}
          </Table>
          <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={(newPage) => dispatch(setCurrentPage(newPage))}
            currentPage={currentPage}
          />
        </Column>
      </Row>
    </>
  );
};

export default GroundTruthListView;
