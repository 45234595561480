import React from "react";
import { createRoot } from "react-dom/client";
import { KatSpinner } from "@amzn/katal-react";
import { Provider } from "react-redux";
import App from "./components/App";
import AppInitWrapper from "./components/AppInitWrapper";
import "./logger";

import "@amzn/katal-components/styles.css";
import { Init } from "src/config/Auth";
import { BrowserRouter } from "react-router-dom";
import store from "src/store/store";
import "./global.scss";
// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

Init();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size="large" />}>
          <App />
        </React.Suspense>
      </AppInitWrapper>
    </Provider>
  </BrowserRouter>,
);
