import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import DataExtraction from "src/components/dataExtraction/dataExtraction";
import ModelManagement from "src/components/modelManagement/modelManagement";
import BatchProcess from "src/components/batchProcess/batchProcess";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getModelById, resetField } from "src/store/modelManagementSlice";
import { CPT_AUDITOR_LDAP_GROUP, DUMMY_MODEL_ID } from "src/constants";
import GroundTruthListView from "src/components/groundTruth/groundTruthListView";
import GroundTruthReview from "src/components/groundTruth/groundTruthReview";
import ValidationWorkflow from "src/components/validationWorkflow/validationWorkflow";
import AuditReviewView from "src/components/audit/auditReview/auditReviewView";
import AuditIncidentListView from "src/components/audit/auditIncidentListView/auditIncidentListView";
import AuditInputUpload from "src/components/audit/auditInputUpload/auditInputUpload";
import ModelGroundTruthListView from "src/components/validationWorkflow/groundTruthListView/modelGroundTruthListView";
import ModelGroundTruthReview from "src/components/validationWorkflow/groundTruthReview/modelGroundTruthReview";

const MainContent = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { selectedModelId } = useAppSelector((state) => state.modelSlice);

  const { ldap, userLoading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (selectedModelId !== "" && selectedModelId !== DUMMY_MODEL_ID) {
      dispatch(getModelById(selectedModelId));
      dispatch(resetField());
    }
  }, [selectedModelId, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<DataExtraction />} />
      <Route path="/dataExtraction" element={<DataExtraction />} />
      <Route path="/modelManagement" element={<ModelManagement />} />
      <Route path="/batchProcess" element={<BatchProcess />} />
      <Route path="/groundTruth/:modelId" element={<GroundTruthListView />} />
      <Route
        path="/groundTruth/:modelId/:index"
        element={<GroundTruthReview />}
      />
      {ldap.includes(CPT_AUDITOR_LDAP_GROUP) && (
        <Route path="/audit" element={<AuditInputUpload />} />
      )}
      {ldap.includes(CPT_AUDITOR_LDAP_GROUP) && (
        <Route
          path="/audit/:auditWorkflowId"
          element={<AuditIncidentListView />}
        />
      )}
      {ldap.includes(CPT_AUDITOR_LDAP_GROUP) && (
        <Route
          path="/audit/:auditWorkflowId/:incidentId"
          element={<AuditReviewView />}
        />
      )}
      <Route path="/validationWorkflow" element={<ValidationWorkflow />} />
      <Route
        path="/validationWorkflow/:modelId"
        element={<ModelGroundTruthListView />}
      />
      <Route
        path="/validationWorkflow/:modelId/:sourceId"
        element={<ModelGroundTruthReview />}
      />

      {userLoading === "fulfilled" && (
        <Route path="*" element={<Navigate to="/" replace />} />
      )}
    </Routes>
  );
};

export default MainContent;
