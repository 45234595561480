import React from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import { Field, ModelView } from "src/store/modelManagementSlice";
import "./recordLine.scss";
import Button from "@amzn/meridian/button";
import { useNavigate } from "react-router-dom";

interface RecordLineProps {
  record: ModelView | Field;
  onClick: () => void;
}

const RecordLine: React.FC<RecordLineProps> = ({ record, onClick }) => {
  const { selectedModel, selectedFieldId, selectedModelId } = useAppSelector(
    (state) => state.modelSlice,
  );

  const navigate = useNavigate();

  const isFieldType = () => (record as Field).description !== undefined;

  const selected = isFieldType()
    ? record.id === selectedFieldId
    : record.id === selectedModelId;

  const getDescription = (record: ModelView | Field) => {
    if (isFieldType()) {
      return (record as Field).description;
    } else {
      return `${(record as ModelView).numberOfLabels} Fields`;
    }
  };

  return (
    <div key={record.id} className="row-style" onClick={onClick}>
      <Row
        className={selected ? "selected-color" : "unselected-color"}
        spacing={"300"}
        widths={!isFieldType() ? ["justify", "60%", "30%"] : undefined}
      >
        {selected ? <div className="pre-line"></div> : <></>}
        <Column spacing={"100"} spacingInset={"200"}>
          <Text type={selected ? "h50" : "b200"}>{record.name}</Text>
          <Text type="b200" color="secondary" truncate>
            {getDescription(record)}
          </Text>
        </Column>
        {selected && !isFieldType() && (
          <Button
            onClick={() => navigate("/validationWorkflow")}
            type={"tertiary"}
            size={"small"}
          >
            validate model
          </Button>
        )}
      </Row>
    </div>
  );
};

export default RecordLine;
