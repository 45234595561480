import React, { useState, useEffect, useCallback, useMemo } from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import ModelSelect from "src/components/shared/modelSelect/modelSelect";
import UploadNewRevision from "src/components/validationWorkflow/uploadNewRevision";
import { HistoricalValidationFlattenCapitalized } from "src/components/validationWorkflow/dataProcess/dataProcessType";
import {
  sortTableDataByColumnName,
  transformHistoricalValidationsToTableData,
  SortDirection,
  getAccuracyFields,
} from "src/components/validationWorkflow/dataProcess/dataProcessUtil";
import {
  getCurrentGroundTruth,
  getHistoricalValidation,
  validate,
  resetCurrentGroundTruthError,
  resetGroundTruthOutput,
  GroundTruthOutput,
  setSelectedUploadId,
  resetHistoricalValidation,
} from "src/store/modelLifeCycleSlice";
import { getGroundTruthDataListView } from "src/store/modelLifeCycleGroundTruthSlice";
import { error } from "src/utils/toastHelper";
import { useNavigate } from "react-router-dom";
import SampleCSVTemplateLink from "src/components/shared/csvTemplate/sampleCSVTemplateLink";
import { SOURCE_ID, SOURCE_TEXT } from "src/constants";
import { formatISO8601TimestampToDateTime } from "src/utils/dateUtil";

const ValidationWorkflow = () => {
  const dispatch = useAppDispatch();
  const { selectedModelId, selectedModel } = useAppSelector(
    (state) => state.modelSlice,
  );

  const {
    selectedUploadId,
    groundTruthOutputs,
    historicalValidations,
    currentGroundTruthError,
  } = useAppSelector((state) => state.modelLifeCycleSlice);

  const [isUploadIdCleared, setIsUploadIdCleared] = useState(false);
  const [sortColumn, setSortColumn] = useState("Date");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Descending,
  );
  const [isInitialSorted, setIsInitialSorted] = useState(false);
  const [tableData, setTableData] = useState<
    HistoricalValidationFlattenCapitalized[]
  >([]);

  const navigate = useNavigate();

  const { groundTruthDataListView } = useAppSelector(
    (state) => state.modelLifeCycleGroundTruthSlice,
  );

  useEffect(() => {
    if (currentGroundTruthError) {
      error(currentGroundTruthError);
      dispatch(resetCurrentGroundTruthError());
    }
  }, [currentGroundTruthError]);

  useEffect(() => {
    setIsInitialSorted(false);
    dispatch(setSelectedUploadId(""));
    dispatch(resetHistoricalValidation());
    setIsUploadIdCleared(true);
    if (selectedModelId) {
      dispatch(getGroundTruthDataListView(selectedModelId));
      dispatch(getCurrentGroundTruth({ modelId: selectedModelId }));
    }
  }, [selectedModelId]);

  useEffect(() => {
    // isUploadIdCleared: ensure dispatch(setSelectedUploadId("")) is executed
    if (isUploadIdCleared && selectedUploadId) {
      dispatch(
        getHistoricalValidation({
          modelId: selectedModelId,
          uploadId: selectedUploadId,
        }),
      );
    }
  }, [selectedUploadId, isUploadIdCleared]);

  useEffect(() => {
    if (tableData.length > 0) {
      sortTableDataByColumnName(tableData, sortColumn, sortDirection);
      setIsInitialSorted(true);
    }
  }, [tableData, sortColumn, sortDirection]);

  const onSort = useCallback(
    ({
      sortColumn,
      sortDirection,
    }: {
      sortColumn: string;
      sortDirection: SortDirection;
    }) => {
      setSortDirection(sortDirection);
      setSortColumn(sortColumn);
    },
    [],
  );

  useEffect(() => {
    if (historicalValidations) {
      setTableData(
        transformHistoricalValidationsToTableData(historicalValidations),
      );
    } else {
      setTableData([]);
    }
  }, [historicalValidations]);

  // TO-DO: get accuracyFields from selectedModel.fields
  const accuracyFields = useMemo(() => {
    if (tableData.length > 0) {
      return getAccuracyFields(
        tableData.reduce((oldest, current) =>
          current.Date < oldest.Date ? current : oldest,
        ),
      );
    }
    return {};
  }, [tableData]);

  const handleValidate = () => {
    if (selectedModelId && selectedUploadId) {
      dispatch(
        validate({
          modelId: selectedModelId,
          uploadId: selectedUploadId,
        }),
      );
      //introduce 1 second timeout to fetch the newly created record
      setTimeout(() => {
        dispatch(
          getHistoricalValidation({
            modelId: selectedModelId,
            uploadId: selectedUploadId,
          }),
        );

        setIsInitialSorted(false);
      }, 1000);
    }
  };

  const toPercentage = (decimal: number, decimals = 2) =>
    (decimal * 100).toFixed(decimals) + "%";

  const groundTruthsExist = () =>
    groundTruthOutputs && groundTruthOutputs.length !== 0;

  return (
    <Column spacingInset="500" spacing="500">
      <Row
        widths={["grid-3"]}
        alignmentHorizontal={"start"}
        alignmentVertical={"bottom"}
      >
        <Column>
          <ModelSelect />
        </Column>
        {selectedModelId !== "" && (
          <Column spacingInset="50">
            <Button
              onClick={() => {
                navigate(`/validationWorkflow/${selectedModelId}`);
              }}
              disabled={
                !groundTruthDataListView || groundTruthDataListView.length === 0
              }
            >
              Review ground truth
            </Button>
          </Column>
        )}
      </Row>
      {selectedModelId !== "" && (
        <SampleCSVTemplateLink
          columnNames={[SOURCE_ID, SOURCE_TEXT].concat(
            Object.values(selectedModel.fields).map((field) => field.name),
          )}
        ></SampleCSVTemplateLink>
      )}
      {selectedModelId && (
        <Row widths={["grid-4"]}>
          <Select
            value={selectedUploadId}
            onChange={(id) => dispatch(setSelectedUploadId(id))}
            placeholder={
              groundTruthsExist() ? "Select a file.." : "Upload a file.."
            }
            width={300}
            disabled={!groundTruthsExist()}
          >
            {groundTruthOutputs?.map(
              ({ fileName, timestamp, uploadId }: GroundTruthOutput) => (
                <SelectOption
                  key={uploadId}
                  value={`${uploadId}`}
                  label={`${fileName}, ${formatISO8601TimestampToDateTime(timestamp)}`}
                ></SelectOption>
              ),
            )}
          </Select>
          <UploadNewRevision />
          <Button onClick={handleValidate} disabled={!selectedUploadId}>
            Validate
          </Button>
        </Row>
      )}
      {selectedModelId && isInitialSorted && tableData.length > 0 && (
        <Table
          headerRows={1}
          showDividers
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={onSort}
        >
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell sortColumn="Date">Date</TableCell>
            <TableCell sortColumn="Status">Status</TableCell>
            {Object.keys(accuracyFields).map((field) => (
              <TableCell key={field} sortColumn={field}>
                {field}
              </TableCell>
            ))}
          </TableRow>
          {tableData.map((row, index) => (
            <TableRow key={row.Id}>
              <TableCell>{index}</TableCell>
              <TableCell>{row.Date}</TableCell>
              <TableCell>{row.Status}</TableCell>
              {Object.keys(accuracyFields).map((field) => (
                <TableCell key={field} sortColumn={field}>
                  {row[field] !== undefined
                    ? toPercentage(row[field], 2)
                    : "NA"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      )}
    </Column>
  );
};

export default ValidationWorkflow;
