export const units = [
  { field: "year", seconds: 31536000 },
  { field: "month", seconds: 2592000 },
  { field: "week", seconds: 604800 },
  { field: "day", seconds: 86400 },
  { field: "hour", seconds: 3600 },
  { field: "minute", seconds: 60 },
  { field: "second", seconds: 1 },
];

const calculateTimeDifference = (time: number) => {
  for (let { field, seconds } of units) {
    const interval = Math.floor(time / seconds);
    if (interval >= 1) {
      return {
        interval: interval,
        unit: field,
      };
    }
  }
  return {
    interval: 0,
    unit: "",
  };
};

export const timeAgo = (date: string | number | Date) => {
  const time = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000,
  );
  const { interval, unit } = calculateTimeDifference(time);
  const suffix = interval === 1 ? "" : "s";
  return `${interval} ${unit}${suffix} ago`;
};

export const convertSeconds = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0) {
    return `${hours} hours : ${minutes} minutes`;
  } else {
    return `${minutes} minutes`;
  }
};

export const formatUnixTimestampToLocaleString = (timestamp: string) => {
  // seconds to milliseconds
  const unixTime = Number(timestamp) * 1000;

  return new Date(unixTime).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

export const formatTimestampToDate = (timestampStr: string) => {
  const date = new Date(timestampStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatISO8601TimestampToDateTime = (
  iso8601TimestampStr: string,
) => {
  const date = new Date(iso8601TimestampStr);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
