import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Divider from "@amzn/meridian/divider";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import documentTokens from "@amzn/meridian-tokens/base/icon/document";
import downloadTokens from "@amzn/meridian-tokens/base/icon/download-large";
import Column from "@amzn/meridian/column";
import {
  BatchJob,
  BatchJobStatus,
  getPreSignUrlAndDownload,
  resetNewCreatedBatchJobId,
} from "src/store/batchExtractionSlice";
import Button from "@amzn/meridian/button";
import {
  colorBlue300,
  colorForest100,
  colorForest400,
  colorRed500,
} from "@amzn/meridian-tokens/base/color";
import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Modal from "@amzn/meridian/modal";
import { convertSeconds, timeAgo } from "src/utils/dateUtil";
import "./batchJobView.scss";

const blueText = css({
  color: colorBlue300,
});

const greenText = css({
  color: colorForest400,
});

const redText = css({
  color: colorRed500,
});

const BatchJobView = () => {
  const { batchJobs, newCreatedBatchJobId } = useAppSelector(
    (state) => state.batchDataExtractionPageSlice,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newCreatedBatchJobId !== "") {
      setTimeout(() => {
        dispatch(resetNewCreatedBatchJobId());
      }, 5000);
    }
  }, [newCreatedBatchJobId]);

  const getExtractionAccuracyModal = (batchJob: BatchJob) => {
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    return (
      <>
        <Button onClick={onOpen} type={"link"}>
          view extraction accuracy
        </Button>
        <Modal
          title="Extraction accuracy"
          open={open}
          onClose={onClose}
          scrollContainer="viewport"
          closeLabel="Close"
          aria-describedby="modal-description"
        >
          <Row widths={"fill"} width={"100%"}>
            <Table headerRows={1} spacing={"small"}>
              <TableRow>
                <TableCell>Field Name</TableCell>
                <TableCell>Accuracy (%)</TableCell>
              </TableRow>
              {Object.keys(batchJob.accuracy).length !== 0 &&
                Object.keys(batchJob.accuracy).map((key) => (
                  <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{batchJob.accuracy[key]}</TableCell>
                  </TableRow>
                ))}
            </Table>
          </Row>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onClose}>
              Close
            </Button>
          </Row>
        </Modal>
      </>
    );
  };

  const getJobStatusComponent = (batchJob: BatchJob) => {
    if (batchJob.status === BatchJobStatus.Completed) {
      return (
        <Column>
          <Button
            type={"secondary"}
            submit={true}
            onClick={() =>
              dispatch(
                getPreSignUrlAndDownload({
                  jobId: batchJob.id,
                  s3URL: batchJob.s3OutputFilePath,
                  fileName: batchJob.s3OutputFilePath.replace("output/", ""),
                }),
              )
            }
          >
            <Icon tokens={downloadTokens} />
            Download
          </Button>
        </Column>
      );
    } else if (batchJob.status === BatchJobStatus.Processing) {
      return <Text className={greenText}>Extraction in progress...</Text>;
    } else if (batchJob.status === BatchJobStatus.Error) {
      return (
        <Text className={redText}>Extraction error, please try again...</Text>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Column spacing={"400"}>
      <Row width="100%" alignmentHorizontal={"start"}>
        <Text fontFamily="bookerly" type={"d50"}>
          Your extraction jobs
        </Text>
      </Row>
      <Row></Row>
      {batchJobs.map((batchJob) => (
        <>
          <Divider spacingAfter="large" spacingBefore="large" />
          <Row
            alignmentHorizontal={"start"}
            widths={["30%", "30%", "40%"]}
            width={"100%"}
            backgroundColor={
              batchJob.id === newCreatedBatchJobId ? colorForest100 : ""
            }
          >
            <Column alignmentVertical={"top"} spacing={"100"}>
              <span className="filename-truncate">
                <Text type={"h200"}>
                  <Icon className={blueText} tokens={documentTokens} />
                  {" " + batchJob.inputFileName}
                </Text>
              </span>
              <Row widths={["8%"]}>
                <Column></Column>
                <Text color={"secondary"}>
                  {timeAgo(batchJob.creationTimestamp)}
                </Text>
              </Row>
            </Column>
            <Column alignmentVertical={"top"} alignmentHorizontal={"start"}>
              <Text>{batchJob.modelName}</Text>
              {batchJob.status === BatchJobStatus.Completed && (
                <Row alignmentHorizontal={"start"} width={"100%"}>
                  <Text>Process time : </Text>
                  <Text>
                    {convertSeconds(Number(batchJob.processingTimeInSeconds))}
                  </Text>
                </Row>
              )}
            </Column>
            <Column
              alignmentVertical={"top"}
              alignmentHorizontal={"center"}
              spacing={"100"}
            >
              {getJobStatusComponent(batchJob)}
              {/* will enable this section after backend feed accuracy data */}
              {/*{batchJob.accuracy && Object.keys(batchJob.accuracy).length > 0 &&*/}
              {/*    <Row alignmentHorizontal={"center"} width={"100%"}>*/}
              {/*        {getExtractionAccuracyModal(batchJob)}*/}
              {/*    </Row>*/}
              {/*}*/}
            </Column>
          </Row>
        </>
      ))}
    </Column>
  );
};
export default BatchJobView;
