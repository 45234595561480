import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import UploadSection from "src/components/audit/auditInputUpload/uploadSection";
import React, { useEffect } from "react";
import ScrollableSection from "src/components/shared/components/scrollableSection";
import AuditJobView from "src/components/audit/auditInputUpload/auditJobView";
import {
  getAuditJobHistory,
  getAuditModels,
} from "src/store/auditDataUploadSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

const AuditInputUpload = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getAuditModels());
      dispatch(getAuditJobHistory());
    }
  }, [token]);

  return (
    <Row
      widths={["26%", "fit", "2%", "fill"]}
      alignmentVertical={"top"}
      spacingInset={"500 none none none"}
    >
      <Column alignmentHorizontal="center">
        <ScrollableSection>
          <UploadSection />
        </ScrollableSection>
      </Column>
      <div className="split-line"></div>
      <Column></Column>
      <ScrollableSection>
        <AuditJobView />
      </ScrollableSection>
    </Row>
  );
};

export default AuditInputUpload;
