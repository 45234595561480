import apiClient from "src/utils/apiClient";

class BatchJobService {
  async getBatchJobs() {
    return await apiClient.get("/extractions/batch/owner");
  }

  async getBatchJobById(job_id: string) {
    return await apiClient.get(`/extractions/batch/${job_id}/`);
  }
}

export default new BatchJobService();
