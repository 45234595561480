import { success, error } from "src/utils/toastHelper";
class FileService {
  async downloadFromPreSignedUrl(preSignedUrl: string): Promise<Response> {
    try {
      const data = await fetch(preSignedUrl, { method: "GET" });
      if (!data.ok) {
        error("Failed to download file");
        throw new Error(`HTTP error ${data.status}`);
      }
      success("Successfully downloaded file");
      return data;
    } catch (err) {
      error("Failed to download file");
      console.error("Error downloading from pre-signed URL:", err);
      return Promise.reject(err);
    }
  }
}

export default new FileService();
