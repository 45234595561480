import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import Divider from "@amzn/meridian/divider";

const ExtractionResult = () => {
  const { extractionOutput } = useAppSelector(
    (state) => state.dataExtractionSlice,
  );

  return extractionOutput.length === 0 ? (
    <></>
  ) : (
    <Column spacingInset="500" spacing="500">
      <Text type="h200">Extracted output...</Text>
      <Row widths={["50%", "50%"]}>
        <Text type={"h300"}>Field</Text>
        <Text type={"h300"}>Output</Text>
      </Row>
      <Divider />
      {Object.values(extractionOutput).map((output) => (
        <>
          <Row widths={["50%", "50%"]} spacingInset={"none 200 200 none"}>
            <Text type={"h300"}>{output.field}</Text>
            <Text>{output.value}</Text>
          </Row>
          {output.quotes.length !== 0 && (
            <>
              <Row>
                <Text type={"h100"}>From description: </Text>
              </Row>
              {output.quotes.map((quote) => (
                <Row>
                  <Text>
                    <i>{`\"${quote}\"`}</i>
                  </Text>
                </Row>
              ))}
            </>
          )}
          <Divider />
        </>
      ))}
    </Column>
  );
};
export default ExtractionResult;
