import React from "react";
import Link from "@amzn/meridian/link";
import Icon from "@amzn/meridian/icon";
import downloadTokens from "@amzn/meridian-tokens/base/icon/download-small";

interface SampleCSVTemplateLinkProps {
  columnNames: string[];
}

const SampleCSVTemplateLink: React.FC<SampleCSVTemplateLinkProps> = ({
  columnNames,
}) => {
  const handleOnClick = () => {
    let csvContent = columnNames.join(",");
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "template.csv");
    pom.click();
  };

  return (
    <Link onClick={() => handleOnClick()}>
      <Icon tokens={downloadTokens} />
      Download sample format template
    </Link>
  );
};

export default SampleCSVTemplateLink;
