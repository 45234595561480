import React from "react";
import { useAppSelector } from "src/store/store";
import FieldView from "src/components/shared/fieldViewSection/fieldView";
import "./fieldView.scss";

const FieldViewSection = () => {
  const { selectedModel, selectedModelId } = useAppSelector(
    (state) => state.modelSlice,
  );

  return selectedModelId === "" ? (
    <></>
  ) : (
    <div className="fieldContainer">
      {Object.values(selectedModel.fields).map((field) => (
        <div className="field">
          <FieldView {...field} />
        </div>
      ))}
    </div>
  );
};

export default FieldViewSection;
