import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import {
  addVisualViewportResizeListener,
  isMobile,
} from '../../utils/mobile-helpers';
import { KatLitElement } from './kat-lit-element';

/**
 * This class exists to allow components to create an "emulated modal" on mobile.
 * To facilitate that, it performs several actions.
 * 1. Toggles css class on body to stop scrolling.
 *      Updates based on derived class `expanded`.
 * 2. Adds the mobile-emulated-modal to the derived class component.
 *      Allows targeted CSS.
 * 3. Queries a components parts and exposes them to a wrapping component.
 *      This defaults to false as it's only needed for nested components like Currency/Region Selector
 */
export class KatLitMobileElement extends KatLitElement {
  // Mirrored in src/style/_mobile.scss
  private static noScrollCssClassname = 'mobile-no-scroll';

  // Derived classes property to toggle body class with
  protected expandedPropertyName = 'expanded';

  // What tells src/style/_mobile.scss to override styles
  @property({ attribute: 'mobile-emulated-modal' })
  protected mobileEmulatedModal = true;

  // Used to exclude outer-most nested component
  @property({ attribute: 'include-exportparts' })
  protected includeExportparts = false;

  /** Parts available */
  @property()
  exportparts?: string;

  connectedCallback(): void {
    super.connectedCallback();

    addVisualViewportResizeListener();
  }

  firstUpdated(): void {
    super.firstUpdated();

    if (!this.includeExportparts) {
      return;
    }

    // Allows nested ShadowDOMs to target child component parts
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/exportparts
    // In the future, we could consider dynamically generating this list.
    this.exportparts = [
      'mobile-emulated-modal-mask',
      'mobile-emulated-modal-wrapper',
      'mobile-emulated-modal-header',
      'mobile-emulated-modal-close',
      'mobile-emulated-modal-container',
      'mobile-emulated-modal-content',
      'mobile-emulated-modal-footer',
      'mobile-emulated-modal-item',
      'mobile-emulated-modal-search',
    ].join(',');
  }

  updated(changedProperties: Map<string, any>): void {
    super.updated(changedProperties);

    // Ignore if not using emulated modal or expanded property
    if (
      !this.mobileEmulatedModal ||
      !changedProperties.has(this.expandedPropertyName)
    ) {
      return;
    }

    // Ignore components that are initializing expanded to false
    if (
      !this[this.expandedPropertyName] &&
      changedProperties.get(this.expandedPropertyName) === undefined
    ) {
      return;
    }

    const isExpanded = this[this.expandedPropertyName];
    document.body?.classList.toggle(
      KatLitMobileElement.noScrollCssClassname,
      isExpanded
    );

    if (isExpanded) {
      this.disableBodyScrolling();
    } else {
      this.enableBodyScrolling();
    }
  }

  protected getMobileHeader(
    expanded: boolean,
    handleClick: (e?: Event) => void,
    ariaLabelClose: string,
    label?: string
  ) {
    if (!expanded) {
      return nothing;
    }

    // Inline style can be removed once we move screenshots to at least 119
    // https://sim.amazon.com/issues/KDS-4205
    return html`<div part="mobile-emulated-modal-header" style="display: none">
      ${label || nothing}<button
        type="button"
        part="mobile-emulated-modal-close"
        @click=${handleClick}
        aria-label=${ariaLabelClose}
      >
        <kat-icon name="close" size="small"></kat-icon>
      </button>
    </div>`;
  }

  // The part attribute supports a space separated list.
  // We allow legacy names (usually component-specific) to avoid a breaking change.
  private getPart(name: string, expanded: boolean, legacyName?: string) {
    const names = [];

    if (expanded) {
      names.push(name);
    }
    if (legacyName) {
      names.push(legacyName);
    }

    return ifDefined(names.join(' '));
  }

  protected getPartMask(expanded: boolean, legacyName?: string) {
    return this.getPart('mobile-emulated-modal-mask', expanded, legacyName);
  }

  protected getPartWrapper(expanded: boolean, legacyName?: string) {
    return this.getPart('mobile-emulated-modal-wrapper', expanded, legacyName);
  }

  protected getPartContainer(expanded: boolean, legacyName?: string) {
    return this.getPart(
      'mobile-emulated-modal-container',
      expanded,
      legacyName
    );
  }

  protected getPartContent(expanded: boolean, legacyName?: string) {
    return this.getPart('mobile-emulated-modal-content', expanded, legacyName);
  }

  protected getPartSearch(expanded: boolean, legacyName?: string) {
    return this.getPart('mobile-emulated-modal-search', expanded, legacyName);
  }

  protected getPartItem(expanded: boolean, legacyName?: string) {
    return this.getPart('mobile-emulated-modal-item', expanded, legacyName);
  }

  // ref: https://weser.io/blog/scroll-blocking-overlays#position--fixed
  scrollOffset;
  // TODO: Remove the || after https://issues.amazon.com/issues/KDS-3172
  // compat/compat warning on IE11, which is deprecated
  /* eslint-disable compat/compat */
  scrollElement =
    (document.scrollingElement as HTMLElement) ||
    (document.documentElement as HTMLElement);

  private disableBodyScrolling() {
    if (!isMobile()) {
      return;
    }

    this.scrollOffset = window.scrollY;

    this.scrollElement.style.overflow = 'hidden';
    this.scrollElement.style.position = 'fixed';
    this.scrollElement.style.top = -this.scrollOffset + 'px';
  }

  private enableBodyScrolling() {
    this.scrollElement.style.removeProperty('position');
    this.scrollElement.style.removeProperty('overflow');
    this.scrollElement.style.removeProperty('top');

    if (isMobile()) {
      window.scrollTo(0, this.scrollOffset);
    }
  }
  /* eslint-enable compat/compat */
}
