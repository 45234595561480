export const isValidRange = (
  firstVisibleIndex: any,
  lastVisibleIndex: any,
  minValue: number,
  maxValue: number,
) => {
  return (
    typeof firstVisibleIndex === "number" &&
    typeof lastVisibleIndex === "number" &&
    !isNaN(firstVisibleIndex) &&
    !isNaN(lastVisibleIndex) &&
    firstVisibleIndex >= minValue &&
    lastVisibleIndex <= maxValue
  );
};
