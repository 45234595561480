import * as KatalMetrics from "@amzn/katal-metrics";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import KatalMetricsDriverConsoleLogJson from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson";
import KatalMetricsDriverArrayCollector from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector";

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === "test") {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== "production") {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    return new KatalMetricsDriverSushi.Builder()
      .withDomainRealm("test", "USAmazon")
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
  }
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite("KatalSampleApp")
    .withServiceName("KatalReactApplication")
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
