import React from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import RecordLine from "../shared/components/recordLine";
import { setSelectedModelId } from "src/store/modelManagementSlice";

const ModelRecords = () => {
  const dispatch = useAppDispatch();
  const { modelView } = useAppSelector((state) => state.modelSlice);

  return (
    <Column spacing={"300"}>
      {Object.values(modelView).map((model) => (
        <RecordLine
          key={model.id}
          record={model}
          onClick={() => {
            dispatch(setSelectedModelId(model.id));
          }}
        />
      ))}
    </Column>
  );
};

export default ModelRecords;
