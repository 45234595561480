import Row from "@amzn/meridian/row";
import UploadSection from "src/components/batchProcess/uploadSection";
import BatchJobView from "src/components/batchProcess/batchJobView";
import React, { useEffect } from "react";
import ScrollableSection from "src/components/shared/components/scrollableSection";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getBatchJobs } from "src/store/batchExtractionSlice";
import Column from "@amzn/meridian/column";

const BatchProcess = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getBatchJobs());
    }
  }, [token]);

  return (
    <Row
      widths={["25%", "fit", "75%"]}
      alignmentVertical={"top"}
      spacingInset={"500"}
      spacing={"600"}
    >
      <Column alignmentHorizontal="end">
        <ScrollableSection>
          <UploadSection />
        </ScrollableSection>
      </Column>
      <div className="split-line"></div>
      <ScrollableSection>
        <BatchJobView />
      </ScrollableSection>
    </Row>
  );
};

export default BatchProcess;
