import React, { useRef } from "react";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import { PAGINATION_OPTIONS } from "src/constants";
import "./paginationWithDropdown.scss";

interface PaginationWithDropdownProps {
  totalPages: number;
  currentPage: number;
  perPage: number;
  setPerPage: (newPerPage: number) => void;
  setCurrentPage: (newPage: number) => void;
}

const paginationWithDropdown = ({
  totalPages,
  currentPage,
  perPage,
  setPerPage,
  setCurrentPage,
}: PaginationWithDropdownProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  return (
    <Row
      className="pagination"
      spacingInset={"500 none none none"}
      alignmentHorizontal={"center"}
    >
      <Pagination
        numberOfPages={totalPages}
        onChange={(newPage) => {
          setCurrentPage(newPage);
        }}
        currentPage={currentPage}
        showSkipArrows={true}
      />
      <Row spacing={"400"} widths={["fill"]} className="pagination__page-size">
        <Text alignment="right">Page size:</Text>
        <Select
          ref={selectRef}
          value={perPage}
          onChange={(perPage) => {
            setPerPage(perPage);
            if (selectRef.current) {
              selectRef.current.blur();
            }
          }}
        >
          {PAGINATION_OPTIONS.map((option) => (
            <SelectOption
              key={option}
              label={option}
              value={option}
            ></SelectOption>
          ))}
        </Select>
      </Row>
    </Row>
  );
};

export default paginationWithDropdown;
