import React from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";

interface TitleAndButtonProps {
  title: string;
  disabled: boolean;
  onClick: (...args: any[]) => any;
}

const TitleAndButton: React.FC<TitleAndButtonProps> = ({
  title,
  disabled,
  onClick,
}) => {
  const buttonTitle = `Add ${title}`;

  return (
    <Row spacingInset={"none 400 none 200"} widths={["fill", "grid-4"]}>
      <Text type="h100">{title}</Text>
      <Button
        size="small"
        type="tertiary"
        disabled={disabled}
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </Row>
  );
};

export default TitleAndButton;
