import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BatchJobService from "src/services/batchJobService";
import commonService from "src/services/commonService";
import ExtractionService, {
  BatchExtractionPayload,
} from "src/services/extractionService";

export enum BatchJobStatus {
  Processing = "Processing",
  Completed = "Completed",
  Error = "Error",
}

export type GetDownloadPreSignedUrlPayload = {
  jobId: string;
  s3URL: string;
  fileName: string;
};

export type BatchJob = {
  id: string;
  s3OutputFilePath: string;
  inputFileName: string;
  modelName: string;
  status: string;
  processingTimeInSeconds: string;
  creationTimestamp: string;
  ownerAlias: string;
  linesProcessed: number;
  linesFailed: number;
  accuracy: Record<string, string>;
};

export type BatchDataExtractionPageState = {
  uploadFile: File | undefined;
  generateLLMClarification: boolean;
  isValidate: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isError: boolean;
  batchJobs: BatchJob[];
  newCreatedBatchJobId: string;
};

const initialState: BatchDataExtractionPageState = {
  uploadFile: undefined,
  generateLLMClarification: true,
  isValidate: false,
  isPending: false,
  isFulfilled: false,
  isError: false,
  batchJobs: [],
  newCreatedBatchJobId: "",
};

export const batchExtraction = createAsyncThunk(
  "batchExtraction",
  async (payload: BatchExtractionPayload) => {
    const { data } = await ExtractionService.batchExtraction(payload);
    return data;
  },
);

export const getBatchJobs = createAsyncThunk("getBatchJobs", async () => {
  const { data } = await BatchJobService.getBatchJobs();
  return data;
});

export const getBatchJobById = createAsyncThunk(
  "getBatchJobById",
  async (jobId: string) => {
    const { data } = await BatchJobService.getBatchJobById(jobId);
    return data;
  },
);

export const getPreSignUrlAndDownload = createAsyncThunk(
  "executeDownloadWorkflow",
  commonService.getPreSignUrlAndDownload,
);

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "batchDataExtractionPageSlice",
  initialState,
  reducers: {
    setUploadFile: (state, action) => {
      state.uploadFile = action.payload;
    },
    resetBatchExtractionApi: (state) => {
      state.isPending = false;
      state.isFulfilled = false;
      state.isError = false;
    },
    setIsValidate: (state, action) => {
      state.isValidate = action.payload;
    },
    setGenerateLLMClarification: (state, action) => {
      state.generateLLMClarification = action.payload;
    },
    resetNewCreatedBatchJobId: (state) => {
      state.newCreatedBatchJobId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(batchExtraction.pending, (state) => {
      state.isPending = true;
      state.isFulfilled = false;
      state.isError = false;
    });
    builder.addCase(batchExtraction.fulfilled, (state, { payload }) => {
      state.isPending = false;
      state.isFulfilled = true;
      state.newCreatedBatchJobId = payload.id;
    });
    builder.addCase(batchExtraction.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
    builder.addCase(getBatchJobs.fulfilled, (state, { payload }) => {
      state.batchJobs = payload;
      state.batchJobs.sort(
        (a: BatchJob, b: BatchJob) =>
          new Date(b.creationTimestamp).valueOf() -
          new Date(a.creationTimestamp).valueOf(),
      );
    });
  },
});

export const {
  setUploadFile,
  resetBatchExtractionApi,
  resetNewCreatedBatchJobId,
  setGenerateLLMClarification,
} = actions;

export default reducer;
