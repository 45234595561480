import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Masthead, { MastheadLink, MastheadTitle } from "@amzn/meridian/masthead";
import logo from "src/images/logo.svg";
import { CPT_AUDITOR_LDAP_GROUP } from "src/constants";
import { useAppSelector } from "src/store/store";

const header = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { ldap } = useAppSelector((state) => state.user);

  return (
    <Masthead>
      <MastheadTitle>
        <img src={logo} onClick={() => navigate("/")} width="400" height="60" />
      </MastheadTitle>
      <MastheadLink
        onClick={() => navigate("/dataExtraction")}
        selected={location.pathname.includes("/dataExtraction")}
      >
        Data Extraction
      </MastheadLink>
      <MastheadLink
        onClick={() => navigate("/modelManagement")}
        selected={location.pathname.includes("/modelManagement")}
      >
        Model Detail Management
      </MastheadLink>
      <MastheadLink
        onClick={() => navigate("/batchProcess")}
        selected={location.pathname.includes("batchProcess")}
      >
        Batch process
      </MastheadLink>
      <MastheadLink
        onClick={() => navigate("/validationWorkflow")}
        selected={location.pathname.includes("validationWorkflow")}
      >
        Validation Workflow
      </MastheadLink>

      {ldap.includes(CPT_AUDITOR_LDAP_GROUP) && (
        <MastheadLink
          onClick={() => navigate("/audit")}
          selected={location.pathname.includes("/audit")}
        >
          Audit Workflow
        </MastheadLink>
      )}
    </Masthead>
  );
};
export default header;
