import { ValidateOutput } from "src/store/modelLifeCycleSlice";
import {
  Field,
  HistoricalValidationFlatten,
  HistoricalValidationFlattenCapitalized,
} from "src/components/validationWorkflow/dataProcess/dataProcessType";
import { formatISO8601TimestampToDateTime } from "src/utils/dateUtil";

// Sorting utility functions
export enum SortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export function sortTableDataByColumnName<
  T extends { [key: string]: string | number | Date },
>(dataArray: T[], sortColumn: string, sortDirection: SortDirection): T[] {
  return sortDirection === SortDirection.Ascending
    ? sortAscending(dataArray, sortColumn)
    : sortAscending(dataArray, sortColumn).reverse();
}

function sortAscending<T extends { [key: string]: string | number | Date }>(
  dataArray: T[],
  sortColumn: string,
): T[] {
  dataArray.sort((a, b) => {
    const first = a[sortColumn];
    const second = b[sortColumn];

    if (typeof first === "string" && typeof second === "string") {
      return first.localeCompare(second);
    } else if (typeof first === "number" && typeof second === "number") {
      return first - second;
    } else if (first instanceof Date && second instanceof Date) {
      return first.getTime() - second.getTime();
    }

    return 0;
  });

  return dataArray;
}

// Data transformation utility functions
export function getAccuracyFields(
  input: HistoricalValidationFlattenCapitalized,
): Field {
  const { Id, Status, Date, ...otherFields } = input;
  return otherFields;
}

export function transformHistoricalValidationsToTableData(
  historicalValidations: ValidateOutput[],
): HistoricalValidationFlattenCapitalized[] {
  const results: HistoricalValidationFlattenCapitalized[] = [];
  const flattenedData = flatten(historicalValidations);

  flattenedData.forEach((obj) => {
    const result: HistoricalValidationFlattenCapitalized = {
      Id: "",
      Status: "",
      Date: "",
    };

    Object.keys(obj).forEach((key) => {
      const capitalizedKey = capitalize(key);
      result[capitalizedKey] = obj[key];
    });

    results.push(result);
  });

  return results;
}

export function flatten(
  historicalValidations: ValidateOutput[],
): HistoricalValidationFlatten[] {
  return historicalValidations.map((historicalValidation) => {
    const { id, status, timestamp, accuracy } = historicalValidation;

    const data: HistoricalValidationFlatten = {
      id,
      date: formatISO8601TimestampToDateTime(timestamp),
      status,
    };

    accuracy?.forEach((item) => {
      data[item.field] = item.accuracy;
    });

    return data;
  });
}

function capitalize(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
