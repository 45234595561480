import {
  LocalizationContextBuilder,
  MessageBundle,
  LocalizationContext,
} from "@amzn/arb-tools";
import translations from "./i18n/translations";
import arbManifest from "./i18n/translations/arbManifest";
import { DEFAULT_LOCALE } from "./constants";
import _ from "lodash";

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export function getLocalizationContext(locale: string) {
  let lc = LOCALIZATION_CONTEXTS.get(locale);

  if (!lc) {
    lc = new LocalizationContextBuilder()
      .withLocale(locale)
      .withDefaultLocale(DEFAULT_LOCALE)
      .build();

    LOCALIZATION_CONTEXTS.set(locale, lc);
  }

  return lc;
}

export function t(
  bundle: MessageBundle,
  stringId: string,
  args?: Record<string, unknown>,
) {
  if (!bundle || !stringId) {
    return undefined;
  }

  if (!args) {
    return bundle.getMessage(stringId);
  }

  return bundle.formatMessage(stringId, args);
}

export function resolveBundleUrlWithFallbacks(
  resourcePackPath: string | undefined,
  locale: string,
  bundle: string,
) {
  if (resourcePackPath && translations[resourcePackPath]) {
    return translations[resourcePackPath];
  }

  if (!resourcePackPath && arbManifest.endpointMap?.[locale]?.[bundle]) {
    return translations[arbManifest.endpointMap[locale][bundle]];
  }

  if (arbManifest.endpointMap?.[DEFAULT_LOCALE]?.[bundle]) {
    return translations[arbManifest.endpointMap[DEFAULT_LOCALE][bundle]];
  }
}

export const createSearchRegExp = (searchQuery = "") => {
  // Escape special characters in the search query
  const escapedQuery = _.escapeRegExp(searchQuery);

  // Create a regular expression with the escaped query and case-insensitive flag
  return new RegExp(escapedQuery, "i");
};
