import React, { useEffect } from "react";
import AppLayout from "@amzn/meridian/app-layout";
import ErrorBoundary from "src/components/shared/errorBoundary/errorBoundary";
import { useAppSelector, useAppDispatch } from "src/store/store";
import { getUser } from "src/store/userSlice";
import Header from "src/components/shared/header/header";
import MainContent from "src/components/shared/mainContent/mainContent";
import { getModels } from "src/store/modelManagementSlice";
import SiteToaster from "src/components/shared/siteToaster/siteToaster";
import { getBatchJobs } from "src/store/batchExtractionSlice";

const App = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);

  /**
   * Only make the getUser() call if the user has agreed to the privacy modal
   * */
  useEffect(() => {
    if (!token) {
      dispatch(getUser());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      dispatch(getModels());
    }
  }, [token]);

  return (
    <ErrorBoundary>
      <AppLayout headerComponent={Header}>
        <SiteToaster />
        <Header />
        <MainContent />
      </AppLayout>
    </ErrorBoundary>
  );
};

export default App;
