import React, { useRef, useState } from "react";
import "./fieldView.scss";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Popover from "@amzn/meridian/popover";
import Text from "@amzn/meridian/text";
import { Field } from "src/store/modelManagementSlice";

const FieldView = (field: Field) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const onHoverButton = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <Button
        type={"tertiary"}
        ref={buttonRef}
        onMouseLeave={onClose}
        onClick={onHoverButton}
      >
        {field.name}
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
      >
        <Column>
          <Text>{"Type : " + field.type}</Text>
          <Text>{"Description : " + field.description}</Text>
        </Column>
      </Popover>
    </>
  );
};

export default FieldView;
