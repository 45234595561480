import React from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { setSelectedModelId } from "src/store/modelManagementSlice";

const ModelSelect = () => {
  const { selectedModelId, modelView } = useAppSelector(
    (state) => state.modelSlice,
  );
  const dispatch = useAppDispatch();

  return (
    <Select
      label={"Please select your model"}
      value={selectedModelId}
      onChange={async (modelId) => {
        dispatch(setSelectedModelId(modelId));
      }}
      placeholder={"please select..."}
      size="medium"
      width={"100%"}
    >
      {Object.values(modelView).map((model) => (
        <SelectOption key={model.id} value={model.id} label={model.name} />
      ))}
    </Select>
  );
};
export default ModelSelect;
