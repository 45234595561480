import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";

enum Timeout {
  Short = 3000,
  Medium = 6000,
  Long = 10000,
}

enum ToastType {
  Success = "success",
  Error = "error",
}

function showToast(type: ToastType, message: string, timeout: number) {
  store.dispatch(
    createToast({
      type: type,
      message: message,
      timeout: timeout,
    }),
  );
}

export const success = (message: string) =>
  showToast(ToastType.Success, message, Timeout.Short);
export const error = (message: string) =>
  showToast(ToastType.Error, message, Timeout.Short);
export const successMedium = (message: string) =>
  showToast(ToastType.Success, message, Timeout.Medium);
export const errorMedium = (message: string) =>
  showToast(ToastType.Error, message, Timeout.Medium);
export const successLong = (message: string) =>
  showToast(ToastType.Success, message, Timeout.Long);
export const errorLong = (message: string) =>
  showToast(ToastType.Error, message, Timeout.Long);
