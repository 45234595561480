import React, { ReactNode } from "react";
import Column from "@amzn/meridian/column";

interface ScrollableSectionProps {
  children: ReactNode;
}

const ScrollableSection: React.FC<ScrollableSectionProps> = ({ children }) => {
  return (
    <Column spacing="500" height="85vh" heights="fit" overflowY="auto">
      {children}
    </Column>
  );
};

export default ScrollableSection;
