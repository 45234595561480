import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import {
  AuditField,
  AuditFieldType,
  AuditStatus,
  getGroundTruthByModelIdAndSequentialId,
  IncorrectType,
  setFieldConfirmedAnswer,
  setFieldIncorrectReason,
  setFieldIndex,
  setFieldNotes,
  setFieldStatus,
  setFieldType,
  updateFieldSingleOrMultiple,
  upsertGroundTruthField,
} from "src/store/groundTruthSlice";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import Select, { SelectOption } from "@amzn/meridian/select";
import Textarea from "@amzn/meridian/textarea";
import Button from "@amzn/meridian/button";
import { Field, getModelById } from "src/store/modelManagementSlice";
import Divider from "@amzn/meridian/divider";
import { error } from "src/utils/toastHelper";

const GroundTruthReview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { modelId, index } = useParams();
  const indexGT = index ? parseInt(index) : -1;

  const {
    groundTruthFieldIndex,
    selectedAuditData,
    getGTByModelIdAndSequentialIdLoading,
    upsertGTFieldLoading,
  } = useAppSelector((state) => state.groundTruthSlice);

  const { selectedModel, getModelByIdLoading } = useAppSelector(
    (state) => state.modelSlice,
  );

  const { token } = useAppSelector((state) => state.user);

  const [validate, setValidate] = useState(false);

  const [
    isLLMCorrectSelectionErrorMessage,
    setIsLLMCorrectSelectionErrorMessage,
  ] = useState("");

  const [answerSelectionErrorMessage, setAnswerSelectionErrorMessage] =
    useState("");

  const [reasonSelectionErrorMessage, setReasonSelectionErrorMessage] =
    useState("");

  const [noteErrorMessage, setNoteErrorMessage] = useState("");

  const findNextField = () => {
    return selectedAuditData.auditFields.findIndex(
      (groundTruthField: AuditField) =>
        groundTruthField.auditStatus === AuditStatus.UNREVIEWED,
    );
  };

  const getFinalAnswer = () => {
    if (
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
      AuditFieldType.NEITHER
    ) {
      return (
        <Select
          label={"Please select final answer"}
          value={
            selectedAuditData.auditFields[groundTruthFieldIndex].confirmedAnswer
          }
          onChange={(answer) => {
            dispatch(
              setFieldConfirmedAnswer({
                fieldIndex: groundTruthFieldIndex,
                confirmedAnswer: answer,
              }),
            );
          }}
          placeholder={"please select..."}
          size="medium"
          width={"50%"}
          errorMessage={answerSelectionErrorMessage}
        >
          {selectModelField?.options.map((option) => (
            <SelectOption value={option} label={option} />
          ))}
        </Select>
      );
    } else if (
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
        AuditFieldType.LLM_CORRECT ||
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
        AuditFieldType.ARBITRARY
    ) {
      dispatch(
        setFieldConfirmedAnswer({
          fieldIndex: groundTruthFieldIndex,
          confirmedAnswer:
            selectedAuditData.auditFields[groundTruthFieldIndex].llmAnswer,
        }),
      );
      return (
        <>
          <Text type={"h200"}>Final answer:</Text>
          <Text>
            {
              selectedAuditData.auditFields[groundTruthFieldIndex]
                .confirmedAnswer
            }
          </Text>
        </>
      );
    } else if (
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
      AuditFieldType.HUMAN_CORRECT
    ) {
      dispatch(
        setFieldConfirmedAnswer({
          fieldIndex: groundTruthFieldIndex,
          confirmedAnswer:
            selectedAuditData.auditFields[groundTruthFieldIndex]
              .userUploadedAnswer,
        }),
      );

      return (
        <>
          <Text type={"h200"}>Final answer:</Text>
          <Text>
            {
              selectedAuditData.auditFields[groundTruthFieldIndex]
                .confirmedAnswer
            }
          </Text>
        </>
      );
    }
  };

  useEffect(() => {
    if (isNaN(indexGT) || indexGT < 0 || !modelId) {
      dispatch(() => navigate("/"));
    } else if (token) {
      dispatch(getModelById(modelId));
      dispatch(
        getGroundTruthByModelIdAndSequentialId({
          model_id: modelId,
          sequential_id: indexGT,
        }),
      );
    }
  }, [indexGT, modelId, token]);

  useEffect(() => {
    if (
      getGTByModelIdAndSequentialIdLoading === "fulfilled" ||
      upsertGTFieldLoading === "fulfilled"
    ) {
      const nextUnreviewedFieldIndex = findNextField();
      if (nextUnreviewedFieldIndex == -1) {
        if (upsertGTFieldLoading !== "fulfilled") {
          dispatch(setFieldIndex(0));
        }
      } else {
        dispatch(setFieldIndex(nextUnreviewedFieldIndex));
      }
    }
  }, [getGTByModelIdAndSequentialIdLoading, upsertGTFieldLoading]);

  useEffect(() => {
    if (
      getModelByIdLoading === "fulfilled" &&
      getGTByModelIdAndSequentialIdLoading === "fulfilled"
    ) {
      dispatch(updateFieldSingleOrMultiple(selectedModel));
    }
  }, [getGTByModelIdAndSequentialIdLoading, getModelByIdLoading]);

  const selectModelField =
    getModelByIdLoading === "fulfilled" &&
    getGTByModelIdAndSequentialIdLoading === "fulfilled" &&
    groundTruthFieldIndex != -1
      ? Object.values(selectedModel.fields).find(
          (label: Field) =>
            label.name ===
            selectedAuditData.auditFields[groundTruthFieldIndex].fieldName,
        )
      : undefined;

  const handleErrorMessage = () => {
    if (!selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect) {
      setIsLLMCorrectSelectionErrorMessage("Please select a correct answer");
      return false;
    } else {
      setIsLLMCorrectSelectionErrorMessage("");
    }

    if (
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
        AuditFieldType.NEITHER &&
      !selectedAuditData.auditFields[groundTruthFieldIndex].confirmedAnswer
    ) {
      setAnswerSelectionErrorMessage("Please select a correct answer");
      return false;
    } else {
      setAnswerSelectionErrorMessage("");
    }
    if (
      (selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
        AuditFieldType.HUMAN_CORRECT ||
        selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
          AuditFieldType.NEITHER) &&
      !selectedAuditData.auditFields[groundTruthFieldIndex].incorrectReason
    ) {
      setReasonSelectionErrorMessage("Please select incorrect reason");
      return false;
    } else {
      setReasonSelectionErrorMessage("");
    }
    if (
      selectedAuditData.auditFields[groundTruthFieldIndex].incorrectReason ===
        IncorrectType.NEED_KNOWLEDGE &&
      !selectedAuditData.auditFields[groundTruthFieldIndex].notes
    ) {
      setNoteErrorMessage("Please enter additional knowledge");
      return false;
    } else if (
      selectedAuditData.auditFields[groundTruthFieldIndex].incorrectReason ===
        IncorrectType.OTHER &&
      !selectedAuditData.auditFields[groundTruthFieldIndex].notes
    ) {
      setNoteErrorMessage("Please enter incorrect reason");
      return false;
    } else {
      setNoteErrorMessage("");
    }
    return true;
  };

  const handleSaveAndNextButton = () => {
    if (
      selectedAuditData.auditFields[groundTruthFieldIndex].isLLMCorrect ===
        AuditFieldType.HUMAN_CORRECT &&
      !selectedAuditData.auditFields[groundTruthFieldIndex].userUploadedAnswer
    ) {
      error(
        "The selected option is 'Human is correct' while the 'Human answer' is empty, please select another option.",
      );
      return;
    }

    if (findNextField() === -1) {
      navigate(`/groundTruth/${modelId}/${indexGT + 1}`);
    } else {
      setValidate(true);
      if (handleErrorMessage()) {
        setValidate(false);
        dispatch(
          setFieldStatus({
            fieldIndex: groundTruthFieldIndex,
            status: AuditStatus.REVIEWED,
          }),
        );
        dispatch(
          upsertGroundTruthField({
            modelId: modelId || "",
            sequentialId: selectedAuditData.sequentialId,
            auditField: selectedAuditData.auditFields[groundTruthFieldIndex],
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (validate && selectedAuditData.auditFields[groundTruthFieldIndex]) {
      handleErrorMessage();
    }
  }, [selectedAuditData.auditFields[groundTruthFieldIndex]]);

  return (
    <>
      {selectedAuditData.auditFields.length > 0 &&
        groundTruthFieldIndex !== -1 && (
          <Row width={"100%"} widths={["25%", "75%"]}>
            <Column
              width="100%"
              spacingInset={"400"}
              alignmentHorizontal={"start"}
            >
              <Text type={"h200"}>Incident ID:</Text>
              <Text>{selectedAuditData.incidentId}</Text>
              {/*<Text>{`Total ground truth: ${groundTruthList.length}`}</Text>*/}
              {/*<Text>{`You are in ${indexGT}th ground truth`}</Text>*/}
              <SideMenu width={"100%"}>
                {selectedAuditData.auditFields.map(
                  (field: AuditField, index: number) => {
                    return (
                      <SideMenuLink
                        selected={
                          selectedAuditData.auditFields[groundTruthFieldIndex]
                            .fieldName === field.fieldName
                        }
                        onClick={() => {
                          dispatch(setFieldIndex(index));
                        }}
                      >
                        <Row>
                          <Column alignmentHorizontal="start">
                            <Text alignment={"left"}>{field.fieldName}</Text>
                          </Column>
                          <Column alignmentHorizontal="end">
                            <Text alignment={"right"}>{field.auditStatus}</Text>
                          </Column>
                        </Row>
                      </SideMenuLink>
                    );
                  },
                )}
              </SideMenu>
            </Column>
            <Column
              width="100%"
              alignmentVertical={"center"}
              alignmentHorizontal="start"
              spacingInset={"400"}
            >
              <Row width="80%">
                <Column>
                  <Text type={"h300"}>Description:</Text>
                  <Text> {selectedAuditData.incidentDescription}</Text>
                </Column>
              </Row>
              {selectedAuditData.auditFields[groundTruthFieldIndex]
                .llmClarification && (
                <Row width="100%">
                  <Text type={"h200"}>LLM Clarification:</Text>
                  <Text>
                    {
                      selectedAuditData.auditFields[groundTruthFieldIndex]
                        .llmClarification
                    }
                  </Text>
                </Row>
              )}
              <Divider />
              <Row width="100%">
                <Text type={"h200"}>Human answer:</Text>
                <Text>
                  {
                    selectedAuditData.auditFields[groundTruthFieldIndex]
                      .userUploadedAnswer
                  }
                </Text>
              </Row>
              <Row width="100%">
                <Text type={"h200"}>LLM answer:</Text>
                <Text>
                  {
                    selectedAuditData.auditFields[groundTruthFieldIndex]
                      .llmAnswer
                  }
                </Text>
              </Row>
              <Row width="100%">
                <Select
                  label={"Which answer is correct?"}
                  value={
                    selectedAuditData.auditFields[groundTruthFieldIndex]
                      .isLLMCorrect
                  }
                  onChange={(type) => {
                    dispatch(
                      setFieldType({
                        groundTruthIndex: indexGT - 1,
                        fieldIndex: groundTruthFieldIndex,
                        isLLMCorrect: type,
                      }),
                    );
                  }}
                  placeholder={"please select..."}
                  size="medium"
                  width={"50%"}
                  errorMessage={isLLMCorrectSelectionErrorMessage}
                >
                  {Object.values(AuditFieldType).map((type) => (
                    <SelectOption key={type} value={type} label={type} />
                  ))}
                </Select>
              </Row>
              <Row width="100%">{getFinalAnswer()}</Row>
              {(selectedAuditData.auditFields[groundTruthFieldIndex]
                .isLLMCorrect === AuditFieldType.HUMAN_CORRECT ||
                selectedAuditData.auditFields[groundTruthFieldIndex]
                  .isLLMCorrect === AuditFieldType.NEITHER) && (
                <Row width="100%">
                  <Select
                    label={"Please select field incorrect reason"}
                    value={
                      selectedAuditData.auditFields[groundTruthFieldIndex]
                        .incorrectReason || ""
                    }
                    onChange={(reason) => {
                      dispatch(
                        setFieldIncorrectReason({
                          fieldIndex: groundTruthFieldIndex,
                          incorrectReason: reason,
                        }),
                      );
                    }}
                    placeholder={"please select..."}
                    size="medium"
                    width={"50%"}
                    errorMessage={reasonSelectionErrorMessage}
                  >
                    {Object.values(IncorrectType).map((incorrectType) => (
                      <SelectOption
                        key={incorrectType}
                        value={incorrectType}
                        label={incorrectType}
                      />
                    ))}
                  </Select>
                </Row>
              )}
              <Row width="100%">
                <Textarea
                  value={
                    selectedAuditData.auditFields[groundTruthFieldIndex].notes
                  }
                  onChange={(notes) =>
                    dispatch(
                      setFieldNotes({
                        fieldIndex: groundTruthFieldIndex,
                        notes: notes,
                      }),
                    )
                  }
                  placeholder="Enter notes for additional knowledge or incorrect reason"
                  label="Enter notes..."
                  size={"small"}
                  width={"50%"}
                  errorMessage={noteErrorMessage}
                />
              </Row>
              <Row width={"80%"} alignmentHorizontal={"end"}>
                <Button onClick={handleSaveAndNextButton}>
                  {findNextField() === -1
                    ? "Save & Navigate to next unreviewed ground truth"
                    : "Save & Navigate to next unreviewed field"}
                </Button>
              </Row>
            </Column>
          </Row>
        )}
    </>
  );
};

export default GroundTruthReview;
