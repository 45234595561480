import apiClient from "src/utils/apiClient";
import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { getAuditJobHistory } from "src/store/auditDataUploadSlice";
import { AuditData } from "src/store/auditSlice";

export interface AuditWorkflowExtractionPayload {
  file: File;
  modelId: string;
}

export interface GetReviewedCasesCSVPayload {
  auditWorkflowId: string;
  modelId: string;
}

class AuditWorkflowService {
  async submitAuditWorkflowExtraction(payload: AuditWorkflowExtractionPayload) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("model_id", payload.modelId);
    const res = await apiClient.post("/audit_workflow/extract", formData);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Audit job submit successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    store.dispatch(getAuditJobHistory());
    return res;
  }

  async getAuditWorkflowHistory() {
    return await apiClient.get("/audit_workflow/history");
  }

  async getAuditDataListView(auditWorkflowId: string) {
    return await apiClient.get(
      `/audit_workflow/audit_data_list?audit_workflow_id=${auditWorkflowId}`,
    );
  }

  async getAuditJobById(auditWorkflowId: string) {
    return await apiClient.get(`/audit_workflow/${auditWorkflowId}`);
  }
  async getAuditData(payload: { auditWorkflowId: string; incidentId: string }) {
    return await apiClient.get(
      `/audit_workflow/audit_data/?audit_workflow_id=${payload.auditWorkflowId}&incident_id=${payload.incidentId}`,
    );
  }

  async updateAuditData(payload: AuditData) {
    const res = await apiClient.put(`/audit_workflow/update`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated audit data successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }

  async getReviewedCasesCSV(payload: GetReviewedCasesCSVPayload) {
    const res = await apiClient.post(
      `/audit_workflow/post_process?audit_workflow_id=${payload.auditWorkflowId}&model_id=${payload.modelId}`,
      {
        responseType: "blob",
      },
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Download review case in csv format successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
    }
    return res;
  }

  async getIncident(incidentId: string) {
    return await apiClient.get(`/audit_workflow/incident/${incidentId}`);
  }
}

export default new AuditWorkflowService();
