import React, { useEffect } from "react";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import {
  setFile,
  uploadNewRevision,
  resetUploadNewRevisionError,
  getCurrentGroundTruth,
} from "src/store/modelLifeCycleSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

const UploadNewRevision = () => {
  const { file } = useAppSelector((state) => state.modelLifeCycleSlice);
  const { selectedModelId } = useAppSelector((state) => state.modelSlice);
  const { uploadNewRevisionError, uploadNewRevisionOutput } = useAppSelector(
    (state) => state.modelLifeCycleSlice,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (file && selectedModelId) {
      const payload = {
        modelId: selectedModelId,
        file: file,
        generateLLMClarification: true,
      };
      dispatch(uploadNewRevision(payload));
    }
  }, [file]);

  useEffect(() => {
    dispatch(resetUploadNewRevisionError());
    dispatch(setFile(null));

    //introduce 1 second timeout to fetch the newly created record
    setTimeout(() => {
      dispatch(getCurrentGroundTruth({ modelId: selectedModelId }));
    }, 1000);
  }, [selectedModelId, uploadNewRevisionOutput]);

  return (
    <FileInput
      type="single"
      accept=".csv"
      uploadButtonType="secondary"
      uploadButtonLabel="Upload new revision"
      onFileAttached={(acceptedFiles) => dispatch(setFile(acceptedFiles[0]))}
    >
      {file && (
        <FileDetails
          file={file}
          cancelUploadButtonLabel={`Cancel, ${file.name}`}
          removeFileButtonLabel={`Delete, ${file.name}`}
          error={!!uploadNewRevisionError}
          errorMessage={uploadNewRevisionError}
          uploadComplete={!!uploadNewRevisionError}
        />
      )}
    </FileInput>
  );
};
export default UploadNewRevision;
