import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GroundTruthService from "src/services/groundTruthService";
import { LOADING_STATE } from "src/types/constants";
import { Model } from "src/store/modelManagementSlice";

export enum AuditStatus {
  REVIEWED = "Reviewed",
  UNREVIEWED = "Unreviewed",
  MATCHED = "Matched",
}

export enum ReviewStatus {
  YES = "YES",
  NO = "NO",
}

export enum AuditFieldType {
  LLM_CORRECT = "LLM is Correct",
  HUMAN_CORRECT = "Human is correct",
  ARBITRARY = "Both are correct",
  NEITHER = "Neither is correct",
}

export enum IncorrectType {
  HALLUCINATION = "Hallucination",
  NEED_KNOWLEDGE = "Safety/FC knowledge is required",
  ADDITION_INFO_NEED = "Additional info is required beyond incident description",
  DATA_QUALITY = "Data quality issue",
  OTHER = "Other (please specify)",
}

const MULTI_OPTION_DELIMITER = "|";

export type AuditField = {
  fieldName: string;
  userUploadedAnswer: string;
  llmClarification: string;
  llmAnswer: string;
  confirmedAnswer: string | string[];
  isLLMCorrect: AuditFieldType | undefined;
  incorrectReason: IncorrectType | undefined;
  auditStatus: AuditStatus;
  notes: string;
};

export type GroundTruthAuditData = {
  incidentId: string;
  sequentialId: number;
  modelId: string;
  incidentDescription: string;
  auditFields: AuditField[];
};

export type GroundTruthState = {
  groundTruthList: Record<number, GroundTruthAuditData>;
  groundTruthTotalCount: number;
  groundTruthReviewedCount: number;
  selectedAuditData: GroundTruthAuditData;
  itemsPerPage: number;
  currentPage: number;
  groundTruthSequentialId: number;
  groundTruthFieldIndex: number;
  getGTByModelIdLoading: LOADING_STATE;
  getGTByModelIdAndSequentialIdLoading: LOADING_STATE;
  getGTStatisticsByModelIdLoading: LOADING_STATE;
  upsertGTFieldLoading: LOADING_STATE;
};

export const initialGroundTruth: GroundTruthAuditData = {
  incidentId: "",
  sequentialId: -1,
  modelId: "",
  incidentDescription: "",
  auditFields: [],
};

const initialState: GroundTruthState = {
  groundTruthList: {},
  groundTruthTotalCount: 0,
  groundTruthReviewedCount: 0,
  selectedAuditData: initialGroundTruth,
  itemsPerPage: 50,
  currentPage: 1,
  groundTruthSequentialId: -1,
  groundTruthFieldIndex: -1,
  getGTByModelIdLoading: "pending",
  getGTByModelIdAndSequentialIdLoading: "pending",
  upsertGTFieldLoading: "pending",
  getGTStatisticsByModelIdLoading: "pending",
};

const convertConfirmedAnswerToString = (payload: AuditField) => {
  if (Array.isArray(payload.confirmedAnswer)) {
    return {
      ...payload,
      confirmedAnswer: payload.confirmedAnswer.join(MULTI_OPTION_DELIMITER),
    };
  } else {
    return payload;
  }
};

export const getGroundTruthByModelId = createAsyncThunk(
  "groundTruth/getGroundTruthByModelId",
  async (payload: {
    model_id: string;
    page_number: number;
    page_size: number;
  }) => {
    const { data } = await GroundTruthService.getGroundTruthByModelId(payload);
    return data;
  },
);

export const getGroundTruthByModelIdAndSequentialId = createAsyncThunk(
  "groundTruth/getGroundTruthByModelIdAndSequentialId",
  async (payload: { model_id: string; sequential_id: number }) => {
    const { data } =
      await GroundTruthService.getGroundTruthByModelIdAndSequentialId(payload);
    return data;
  },
);

export const getGroundTruthStatisticsByModelId = createAsyncThunk(
  "groundTruth/getGroundTruthStatisticsByModelId",
  async (model_id: string) => {
    const { data } =
      await GroundTruthService.getGroundTruthStatisticsByModelId(model_id);
    return data;
  },
);

export const upsertGroundTruthField = createAsyncThunk(
  "groundTruth/upsertGroundTruthField",
  async (
    payload: { modelId: string; sequentialId: number; auditField: AuditField },
    { getState },
  ) => {
    const { data } = await GroundTruthService.upsertGroundTruthField({
      model_id: payload.modelId,
      sequential_id: payload.sequentialId,
      audit_field: convertConfirmedAnswerToString(payload.auditField),
    });
    return data;
  },
);

export const toAuditFieldStatus = (item: any) => {
  if (item.confirmedAnswer) {
    return item.auditStatus;
  } else if (item.llmAnswer === item.userUploadedAnswer) {
    return AuditStatus.MATCHED;
  } else {
    return AuditStatus.UNREVIEWED;
  }
};

export const toAuditFields = (item: any) => {
  const status = toAuditFieldStatus(item);
  return {
    ...item,
    confirmedAnswer:
      status === AuditStatus.UNREVIEWED &&
      item.userUploadedAnswer === item.llmAnswer
        ? item.llmAnswer
        : item.confirmedAnswer,
    auditStatus: status,
  };
};

export const toGroundTruthAuditData = (item: any) => {
  return {
    incidentId: item.incidentId,
    sequentialId: item.sequentialId,
    modelId: item.modelId,
    incidentDescription: item.incidentDescription,
    auditFields: item.auditFields.map((auditField: any) =>
      toAuditFields(auditField),
    ),
  };
};

const getConfirmedAnswer = (
  confirmedAnswerPayload: string | string[],
  many: boolean,
) => {
  if (typeof confirmedAnswerPayload === "string") {
    return many
      ? confirmedAnswerPayload.split(MULTI_OPTION_DELIMITER)
      : confirmedAnswerPayload;
  } else {
    return many ? [] : "";
  }
};

export const updateAuditData = (
  groundTruthAuditData: GroundTruthAuditData,
  model: Model,
) => {
  const fieldMap: Record<string, boolean> = {};
  Object.values(model.fields).forEach((field) => {
    fieldMap[field.name] = field.many;
  });
  return {
    ...groundTruthAuditData,
    auditFields: groundTruthAuditData.auditFields.map((field) =>
      updateAuditField(field, fieldMap[field.fieldName]),
    ),
  };
};

export const updateAuditField = (field: AuditField, many: boolean) => {
  return {
    ...field,
    confirmedAnswer: getConfirmedAnswer(field.confirmedAnswer, many),
  };
};

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "groundTruthReviewPageSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setItemPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
    },
    setSelectAuditData: (state, { payload }) => {
      state.selectedAuditData = payload;
    },
    setGroundTruthSequentialId: (state, { payload }) => {
      state.groundTruthSequentialId = payload;
    },
    setFieldIndex: (state, { payload }) => {
      state.groundTruthFieldIndex = payload;
    },
    updateFieldSingleOrMultiple: (state, { payload }) => {
      state.selectedAuditData = updateAuditData(
        state.selectedAuditData,
        payload,
      );
    },
    setFieldType: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].isLLMCorrect =
        payload.isLLMCorrect;
    },
    setFieldIncorrectReason: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].incorrectReason =
        payload.incorrectReason;
    },
    setFieldNotes: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].notes =
        payload.notes;
    },
    setFieldConfirmedAnswer: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].confirmedAnswer =
        payload.confirmedAnswer;
    },
    setFieldStatus: (state, { payload }) => {
      state.selectedAuditData.auditFields[payload.fieldIndex].auditStatus =
        payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroundTruthByModelId.pending, (state) => {
      state.getGTByModelIdLoading = "pending";
    });
    builder.addCase(getGroundTruthByModelId.fulfilled, (state, { payload }) => {
      payload.forEach(
        (item: any) =>
          (state.groundTruthList[item.sequentialId] =
            toGroundTruthAuditData(item)),
      );
      state.getGTByModelIdLoading = "fulfilled";
    });
    builder.addCase(getGroundTruthByModelId.rejected, (state) => {
      state.getGTByModelIdLoading = "rejected";
    });

    builder.addCase(upsertGroundTruthField.pending, (state) => {
      state.upsertGTFieldLoading = "pending";
    });
    builder.addCase(upsertGroundTruthField.fulfilled, (state, { payload }) => {
      state.upsertGTFieldLoading = "fulfilled";
    });
    builder.addCase(upsertGroundTruthField.rejected, (state) => {
      state.upsertGTFieldLoading = "rejected";
    });

    builder.addCase(getGroundTruthByModelIdAndSequentialId.pending, (state) => {
      state.getGTByModelIdAndSequentialIdLoading = "pending";
    });
    builder.addCase(
      getGroundTruthByModelIdAndSequentialId.fulfilled,
      (state, { payload }) => {
        state.getGTByModelIdAndSequentialIdLoading = "fulfilled";
        state.selectedAuditData = toGroundTruthAuditData(payload);
      },
    );
    builder.addCase(
      getGroundTruthByModelIdAndSequentialId.rejected,
      (state) => {
        state.getGTByModelIdAndSequentialIdLoading = "rejected";
      },
    );

    builder.addCase(getGroundTruthStatisticsByModelId.pending, (state) => {
      state.getGTByModelIdAndSequentialIdLoading = "pending";
    });
    builder.addCase(
      getGroundTruthStatisticsByModelId.fulfilled,
      (state, { payload }) => {
        state.groundTruthTotalCount = payload.totalCount;
        state.groundTruthReviewedCount = payload.reviewedCount;
        state.getGTStatisticsByModelIdLoading = "fulfilled";
      },
    );
    builder.addCase(getGroundTruthStatisticsByModelId.rejected, (state) => {
      state.getGTStatisticsByModelIdLoading = "rejected";
    });
  },
});

export const {
  setItemPerPage,
  setCurrentPage,
  setFieldIndex,
  setFieldType,
  setFieldIncorrectReason,
  setFieldConfirmedAnswer,
  updateFieldSingleOrMultiple,
  setFieldNotes,
  setFieldStatus,
} = actions;

export default reducer;
